import React, { useState, useEffect } from 'react';

import { convertParagraphToHeader4 } from 'Services/Utils/convertParagraphToHeader4';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/DescriptionLawyer.module.scss';
import 'Assets/quill.css';

const DescriptionLawyer = (props) => {
  let { lawyer } = props;
  const [profileImage, setProfileImage] = useState(null);

  const [ReactQuill, setReactQuill] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const loadQuill = async () => {
        const Quill = await import('react-quill');
        setReactQuill(() => Quill.default);
      };
      loadQuill();
    }
  }, []);

  useEffect(() => {
    if (ReactQuill) {
      // ssr might not correctly apply the font sizes. This is a workaround:
      // go to Assets/quill.css and change the ql-container h5-4-3-2-1 accordingly;

      let SizeStyle = ReactQuill.Quill.import('attributors/style/size');
      SizeStyle.whitelist = ['18px', '22px', '26px', '14px'];
      ReactQuill.Quill.register(SizeStyle, true);
    }
  }, [ReactQuill]);

  useEffect(() => {
    if (lawyer.profileImage) {
      setProfileImage(lawyer.profileImage);
    }
  }, [lawyer]);

  return (
    <>
      {lawyer.subscription > 1 && lawyer.self_description && (
        <div className={styles.descriptionLawyerOuterContainer}>
          {lawyer.self_description && (
            <div className={styles.descriptionLawyerInnerContainer}>
              <div className={styles.descriptionLawyerContainer}>
                <h2 className={styles.descriptionLawyerTitle}>
                  {LPTranslate('LPP_Details_About_Me')}
                </h2>
                <div className={styles.descriptionLawyerText}>
                  {ReactQuill ? (
                    <ReactQuill
                      value={convertParagraphToHeader4(lawyer.self_description)}
                      readOnly={true}
                      theme={'bubble'}
                    />
                  ) : (
                    <p>Loading editor...</p>
                  )}
                </div>

                {/* TODO: read more button */}
                {/* <button className={styles.moreButton} variant="text">Mehr</button> */}
              </div>

              {profileImage && (
                <div className={styles.descriptionLawyerImageContainer}>
                  <img
                    className={styles.descriptionLawyerImage}
                    src={profileImage}
                    alt="profilePicture"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DescriptionLawyer;
