import * as React from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from '@mui/material/Popper';

import Checkbox from 'Components/Lawyer/FilterCheckBox';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import downArrow from 'Assets/pictures/chevron-down.svg';
import briefcase from 'Assets/pictures/briefcase.svg';

import 'Assets/styles/inputs/AutocompleteSelectAOL.scss';

let filter = {
  name: 'Rechtsgebiete',
  type: 'checkboxes',
  boxes: {
    Abfallrecht: { id: 1, checked: false },
    Abgabenrecht: { id: 2, checked: false },
    Adoptionsrecht: { id: 3, checked: false },
    Agrarrecht: { id: 4, checked: false },
    Aktienrecht: { id: 5, checked: false },
    'Allgemeines Gleichbehandlungsgesetz (AGG)': { id: 6, checked: false },
    Arbeitsförderungsrecht: { id: 7, checked: false },
    Arbeitsrecht: { id: 8, checked: false },
    Architektenrecht: { id: 9, checked: false },
    Arzneimittelrecht: { id: 10, checked: false },
    Arzthaftungsrecht: { id: 11, checked: false },
    Arztrecht: { id: 12, checked: false },
    Atomrecht: { id: 13, checked: false },
    Ausbildungsförderungsrecht: { id: 14, checked: false },
    'Ausländisches Recht': { id: 15, checked: false },
    'Bank- und Kapitalmarktrecht': { id: 16, checked: false },
    'Bau- und Architektenrecht': { id: 17, checked: false },
    Bauplanungsrecht: { id: 18, checked: false },
    Baurecht: { id: 19, checked: false },
    'Baurecht (öffentliches)': { id: 21, checked: false },
    'Baurecht (privates)': { id: 20, checked: false },
    Bauträgerrecht: { id: 22, checked: false },
    Beamtenrecht: { id: 23, checked: false },
    Beamtenversorgungsrecht: { id: 24, checked: false },
    Behindertenrecht: { id: 25, checked: false },
    'Berg- und Abgrabungsrecht': { id: 26, checked: false },
    Berufsbildungsrecht: { id: 27, checked: false },
    Berufsrecht: { id: 28, checked: false },
    'Berufsrecht (andere freie Berufe)': { id: 29, checked: false },
    'Berufsrecht (anwaltliches)': { id: 30, checked: false },
    Betäubungsmittelstrafrecht: { id: 34, checked: false },
    Betreuungsrecht: { id: 31, checked: false },
    'Betriebliche Altersversorgung': { id: 32, checked: false },
    Betriebskostenrecht: { id: 33, checked: false },
    'Bio- und Gentechnologierecht': { id: 35, checked: false },
    Börsenrecht: { id: 37, checked: false },
    'Buchführung und Bilanzwesen': { id: 36, checked: false },
    Compliance: { id: 38, checked: false },
    Datenschutzrecht: { id: 39, checked: false },
    Denkmalschutzrecht: { id: 40, checked: false },
    Designrecht: { id: 41, checked: false },
    Dienstrecht: { id: 42, checked: false },
    'EDV-Recht': { id: 43, checked: false },
    'Ehe- und Familienrecht': { id: 44, checked: false },
    'Scheidungsrecht': { id: 45, checked: false },
    'Einbürgerungs- und Staatsangehörigkeitsrecht': { id: 46, checked: false },
    Einkommensteuerrecht: { id: 47, checked: false },
    'Energie- und Energiewirtschaftsrecht': { id: 48, checked: false },
    'Enteignungs- und Umlegungsrecht': { id: 49, checked: false },
    Erbbaurecht: { id: 50, checked: false },
    Erbrecht: { id: 51, checked: false },
    'Erbschaft- und Schenkungsteuerrecht': { id: 52, checked: false },
    Erschließungsrecht: { id: 53, checked: false },
    'Europäische Menschenrechte': { id: 55, checked: false },
    Europarecht: { id: 54, checked: false },
    Fahrerlaubnisrecht: { id: 56, checked: false },
    Familienrecht: { id: 57, checked: false },
    'Familienrechtliche Mediation': { id: 58, checked: false },
    Fischereirecht: { id: 59, checked: false },
    Franchiserecht: { id: 60, checked: false },
    'Gebührenrecht (anwaltliches)': { id: 61, checked: false },
    Gesellschaftsrecht: { id: 62, checked: false },
    Gewerberaummietrecht: { id: 63, checked: false },
    Gewerberecht: { id: 64, checked: false },
    'Gewerblicher Rechtsschutz': { id: 65, checked: false },
    Grundbuchrecht: { id: 66, checked: false },
    Grundsteuerrecht: { id: 67, checked: false },
    Grundstücksrecht: { id: 68, checked: false },
    Güterkraftverkehrsrecht: { id: 69, checked: false },
    Güterrecht: { id: 70, checked: false },
    Haftpflichtrecht: { id: 71, checked: false },
    Haftpflichtversicherungsrecht: { id: 72, checked: false },
    'Handels- und Gesellschaftsrecht': { id: 73, checked: false },
    Handelsrecht: { id: 74, checked: false },
    Immissionsschutzrecht: { id: 76, checked: false },
    Immobilienverkäufe: { id: 77, checked: false },
    Individualarbeitsrecht: { id: 78, checked: false },
    Informationstechnologierecht: { id: 79, checked: false },
    Insolvenzrecht: { id: 80, checked: false },
    Insolvenzverwaltung: { id: 81, checked: false },
    'Internationales Familienrecht': { id: 82, checked: false },
    'Internationales Privatrecht': { id: 83, checked: false },
    'Internationales Steuerrecht': { id: 84, checked: false },
    'Internationales Wirtschaftsrecht': { id: 85, checked: false },
    'Internationales/Europäisches Strafrecht': { id: 86, checked: false },
    Internetrecht: { id: 87, checked: false },
    'IT-Recht': { id: 75, checked: false },
    Jagdrecht: { id: 88, checked: false },
    Jugendstrafrecht: { id: 89, checked: false },
    Kapitalanlagerecht: { id: 90, checked: false },
    Kapitalmarktrecht: { id: 91, checked: false },
    Kapitalstrafrecht: { id: 92, checked: false },
    Kartellrecht: { id: 93, checked: false },
    Kassenarztrecht: { id: 94, checked: false },
    Kaufrecht: { id: 95, checked: false },
    Kindergeldrecht: { id: 96, checked: false },
    Kindschaftsrecht: { id: 97, checked: false },
    Kirchenrecht: { id: 98, checked: false },
    'Kollektives Arbeitsrecht': { id: 99, checked: false },
    'Kommunal- und Kommunalverfassungsrecht': { id: 100, checked: false },
    'Körperschaft- und Gewerbesteuerrecht': { id: 105, checked: false },
    Kostenrecht: { id: 101, checked: false },
    Krankenhausrecht: { id: 102, checked: false },
    Krankenversicherungsrecht: { id: 103, checked: false },
    Kreditversicherungsrecht: { id: 104, checked: false },
    Kündigungsschutzrecht: { id: 106, checked: false },
    'Landschafts- und Naturschutzrecht': { id: 107, checked: false },
    Landwirtschaftsrecht: { id: 108, checked: false },
    Leasingrecht: { id: 109, checked: false },
    Lebensmittelrecht: { id: 110, checked: false },
    Lizenzrecht: { id: 111, checked: false },
    Luftfahrtrecht: { id: 112, checked: false },
    Maklerrecht: { id: 113, checked: false },
    Markenrecht: { id: 114, checked: false },
    Mediation: { id: 115, checked: false },
    Medizinrecht: { id: 116, checked: false },
    'Miet- und Pachtrecht': { id: 117, checked: false },
    'Miet- und Wohnungseigentumsrecht': { id: 118, checked: false },
    Migrationsrecht: { id: 119, checked: false },
    'Mitbestimmungs- und Betriebsverfassungsrecht': { id: 120, checked: false },
    Mutterschutzrecht: { id: 121, checked: false },
    Nachbarrecht: { id: 122, checked: false },
    Notarrecht: { id: 124, checked: false },
    Oldtimerrecht: { id: 125, checked: false },
    Opferrecht: { id: 126, checked: false },
    Passagierflugrecht: { id: 127, checked: false },
    Patentrecht: { id: 128, checked: false },
    Patientenrecht: { id: 129, checked: false },
    Personenbeförderungsrecht: { id: 130, checked: false },
    Personenversicherungsrecht: { id: 131, checked: false },
    Pferderecht: { id: 132, checked: false },
    Pflegeversicherungsrecht: { id: 133, checked: false },
    'Polizei- und Ordnungsrecht': { id: 134, checked: false },
    'Presse- und Medienrecht': { id: 135, checked: false },
    Produkthaftungsrecht: { id: 136, checked: false },
    Prüfungsrecht: { id: 137, checked: false },
    'Recht der Kreditsicherung': { id: 138, checked: false },
    'Recht der Neuen Medien': { id: 139, checked: false },
    Reiserecht: { id: 140, checked: false },
    Rentenversicherungsrecht: { id: 141, checked: false },
    Schiedsgerichtsbarkeit: { id: 142, checked: false },
    'Schul- und Hochschulrecht': { id: 143, checked: false },
    'See- und Schifffahrtsrecht': { id: 144, checked: false },
    Sexualstrafrecht: { id: 145, checked: false },
    Sorgerecht: { id: 146, checked: false },
    Sozialabgabenrecht: { id: 147, checked: false },
    Sozialhilferecht: { id: 148, checked: false },
    Sozialrecht: { id: 149, checked: false },
    Sozialversicherungsrecht: { id: 150, checked: false },
    'Speditions- und Frachtrecht': { id: 151, checked: false },
    Sportrecht: { id: 152, checked: false },
    'Staats- und Verfassungsrecht': { id: 153, checked: false },
    'Staatshaftungs- und Entschädigungsrecht': { id: 154, checked: false },
    Steuerrecht: { id: 155, checked: false },
    Steuerstrafrecht: { id: 156, checked: false },
    Stiftungsrecht: { id: 157, checked: false },
    'Straf- und Strafverfahrensrecht': { id: 158, checked: false },
    Strafrecht: { id: 159, checked: false },
    'Straßen- und Wegerecht': { id: 160, checked: false },
    Tarifvertragsrecht: { id: 161, checked: false },
    Telekommunikationsrecht: { id: 162, checked: false },
    Testamentsvollstreckung: { id: 163, checked: false },
    Tierschutzrecht: { id: 164, checked: false },
    'Transport- und Speditionsrecht': { id: 165, checked: false },
    Transportversicherungsrecht: { id: 166, checked: false },
    Umsatzsteuerrecht: { id: 167, checked: false },
    Umweltrecht: { id: 168, checked: false },
    Umweltstrafrecht: { id: 169, checked: false },
    Unfallversicherungsrecht: { id: 170, checked: false },
    Unterhaltsrecht: { id: 171, checked: false },
    Unternehmensnachfolgerecht: { id: 172, checked: false },
    'Urheber- und Medienrecht': { id: 173, checked: false },
    'Urheber- und Verlagsrecht': { id: 174, checked: false },
    'Vereins- und Verbandsrecht': { id: 175, checked: false },
    Vergaberecht: { id: 176, checked: false },
    Verkehrsrecht: { id: 177, checked: false },
    'Verkehrsstraf- und OWi-Recht': { id: 178, checked: false },
    Verkehrsversicherungsrecht: { id: 179, checked: false },
    Verkehrsverwaltungsrecht: { id: 180, checked: false },
    Verkehrszivilrecht: { id: 181, checked: false },
    Versicherungsrecht: { id: 182, checked: false },
    Versorgungsausgleich: { id: 183, checked: false },
    Vertragsrecht: { id: 184, checked: false },
    Verwaltungsrecht: { id: 185, checked: false },
    Völkerrecht: { id: 186, checked: false },
    Waffenrecht: { id: 187, checked: false },
    Wasserrecht: { id: 188, checked: false },
    'Wechsel- und Scheckrecht': { id: 189, checked: false },
    Wehrrecht: { id: 190, checked: false },
    Wettbewerbsrecht: { id: 191, checked: false },
    Wirtschaftsmediation: { id: 192, checked: false },
    Wirtschaftsrecht: { id: 193, checked: false },
    Wirtschaftsstrafrecht: { id: 194, checked: false },
    Wirtschaftsverwaltungsrecht: { id: 195, checked: false },
    Wohnraummietrecht: { id: 196, checked: false },
    Wohnungseigentumsrecht: { id: 197, checked: false },
    Zivilrecht: { id: 198, checked: false },
    Zollrecht: { id: 199, checked: false },
    Zwangsversteigerungsrecht: { id: 200, checked: false },
    Zwangsvollstreckungsrecht: { id: 201, checked: false },
  },
};

const AutocompleteSelectAOL = (props) => {
  const [keys, setKeys] = React.useState([]);
  const [value] = React.useState(filter.boxes);
  const [isOpen, setIsOpen] = React.useState(false);
  const [nrSelectedOptions, setNrSelectedOptions] = React.useState(0);

  const onSelect = (newValue) => {
    filter.boxes[newValue].checked = !filter.boxes[newValue].checked;

    props.onChange(filter.boxes[newValue].id);
  };

  React.useEffect(() => {
    let boxIds = props.boxes;

    if (boxIds.length > 0) {
      for (const value of Object.entries(filter.boxes)) {
        if (boxIds.includes(value[1].id)) {
          value[1].checked = true;
          boxIds = boxIds.filter((item) => item !== value.id);
        }

        if (boxIds.length === 0) {
          break;
        }
      }
    } else {
      for (const value of Object.entries(filter.boxes)) {
        value.checked = false;
      }
    }

    let checkedBoxes = [];
    let uncheckedBoxes = [];

    for (const [key, value] of Object.entries(filter.boxes)) {
      if (value.checked) {
        checkedBoxes.push(key);
      } else {
        uncheckedBoxes.push(key);
      }
    }

    let sortedBoxes = checkedBoxes.concat(uncheckedBoxes);

    let keys = sortedBoxes;
    setKeys(keys);
    setNrSelectedOptions(checkedBoxes.length);
  }, [props.boxes]);

  const PopperMy = React.useCallback(function (props) {
    return (
      <Popper
        {...props}
        className="lawp-autocomplete-select-aol-popper"
        placement="top-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ]}
      />
    );
  }, []);

  return (
    <div className="lawp-autocomplete-select-aol-container">
      <Autocomplete
        onClose={() => {
          setIsOpen(false);
        }}
        disableClearable
        className="editLawyerAutoCompleteStyling"
        options={keys}
        open={isOpen}
        PopperComponent={PopperMy}
        onChange={onSelect}
        autoHighlight
        getOptionLabel={(option) => option}
        data-cy="area-autocomplete"
        renderOption={(props, option) => (
          <div
            key={value[option].id}
            className="lawp-autocomplete-select-aol-popper-list-option-container"
            onClick={() => {
              onSelect(option);
            }}
            data-cy={`area-checkbox-${value[option].id}`}
          >
            <Checkbox checked={value[option].checked} />
            <p className="lawp-autocomplete-select-aol-list-option">{option}</p>
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onClick={() => {
              setIsOpen(true);
            }}
            data-cy="area-options"
            className="searchTextFieldAutoComplete"
            placeholder={LPTranslate('Common_Legal_Fields')}
            InputProps={{
              ...params.InputProps,

              startAdornment: (
                <InputAdornment position="start">
                  <img src={briefcase} alt="briefcase" className="start-icon" />
                  {nrSelectedOptions !== 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        left: '30px',
                        top: '10px',
                        backgroundColor: '#f9f5ff',
                        borderRadius: '50%',
                        maxWidth: '20px',
                        maxHeight: '20px',
                        minWidth: '20px',
                        minHeight: '20px',
                        color: '#6941c6',
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        '&:hover': { backgroundColor: 'transparent' },
                      }}
                    >
                      {nrSelectedOptions}
                    </div>
                  )}
                </InputAdornment>
              ),

              endAdornment: (
                <React.Fragment>
                  <img className="end-icon" src={downArrow} alt="downArrow" />
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default AutocompleteSelectAOL;
