import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SimpleTextField from 'Components/Inputs/SimpleTextField';
import CircleRadioFilter from 'Components/Filters/CircleRadioFilter.js';
import SetMeetingCalendarModal from 'Components/Modals/SetMeetingCalendarModal';

import { UPDATE_APPOINTMENT } from 'Services/Queries/common';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import calendarIcon from 'Assets/pictures/calendar.svg';

import UpdatePasswordConfirmationModal from 'Components/Modals/UpdatePasswordConfirmationModal';

import moment from 'moment';

import defaultAvatar from 'Assets/pictures/avatarIcon.svg';

import styles from 'Assets/styles/client/suggestNewAppointmentModal.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SuggestNewAppointmentModal = (props) => {
  let { propsData, role, appointmentDate, startAppointment, endAppointment } =
    props;

  const [updateAppointment, { data }] = useMutation(UPDATE_APPOINTMENT);
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const indexWeekday = {
    Monday: LPTranslate('Common2_Monday'),
    Tuesday: LPTranslate('Common2_Tuesday'),
    Wednesday: LPTranslate('Common2_Wednesday'),
    Thursday: LPTranslate('Common2_Thursday'),
    Friday: LPTranslate('Common2_Friday'),
    Saturday: LPTranslate('Common2_Saturday'),
    Sunday: LPTranslate('Common2_Sunday'),
  };

  const [dateToBeUpdated, setDateToBeUpdated] = useState(
    indexWeekday[moment.unix(propsData.date).format('dddd')] +
      ', ' +
      moment.unix(propsData.date).format('DD.MM.YYYY')
  );

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const [open, setOpen] = useState(false);
  const [nextStepDate, setSelectedDay] = useState();
  const [nextStepHour, setSelectedHour] = useState();
  const [shownModal, setShownModal] = useState(0);
  const handleOpen = () => setOpen(true);

  const [formValues, setFormValues] = useState({});

  const [formErrors, setFormErrors] = useState({
    title: '',
    description: '',
    phoneNumber: '',
    addressValue: '',
    date: '',
  });
  // const [meetingVal, setMeetingVal] = useState(
  //   propsData.appointment_type === 'Vor-Ort Meeting' ? 1 : 0
  // );
  const [meetingVal, setMeetingVal] = useState(0);

  const [meetingOptions, setMeetingOptions] = useState([]);

  useEffect(() => {
    if (props.availability.by_phone && props.availability.personally) {
      setFormValues({
        title: '',
        description: '',
        phoneNumber: propsData.lawyer.telephone,
        addressValue: {
          street: propsData.lawyer.street,
          city: propsData.lawyer.location,
          house_number: propsData.lawyer.house_number,
          zipcode: propsData.lawyer.postcode,
          country: propsData.lawyer.country,
        },
      });

      setMeetingOptions([
        {
          _id: 1,
          name: LPTranslate('LSA_Update_Appointment_Call'),
          value: 'phone',
          checked: false,
        },
        {
          _id: 2,
          name: LPTranslate('LSA_Update_Appointment_Site_Meeting'),
          value: 'address',
          checked: false,
        },
      ]);
    } else if (!props.availability.by_phone && props.availability.personally) {
      setFormValues({
        title: '',
        description: '',
        addressValue: {
          street: propsData.lawyer.street,
          city: propsData.lawyer.location,
          house_number: propsData.lawyer.house_number,
          zipcode: propsData.lawyer.postcode,
          country: propsData.lawyer.country,
        },
      });

      setMeetingOptions([
        {
          _id: 1,
          name: LPTranslate('LSA_Update_Appointment_Site_Meeting'),
          value: 'address',
          checked: false,
        },
      ]);
    } else if (props.availability.by_phone && !props.availability.personally) {
      setFormValues({
        title: '',
        description: '',
        phoneNumber: propsData.lawyer.telephone,
      });

      setMeetingOptions([
        {
          _id: 1,
          name: LPTranslate('LSA_Update_Appointment_Call'),
          value: 'phone',
          checked: false,
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShownModal = (day, hour, nr) => {
    setDateToBeUpdated(day);
    setFormErrors({ ...formErrors, date: '' });

    setSelectedDay(day);
    setSelectedHour(hour);
    setShownModal(nr);
  };

  const cancelVacation = () => {
    setSelectedDay();
    setSelectedHour();
    setShownModal(1);
  };

  const onChangeDescriptionValue = (value) => {
    setFormValues({ ...formValues, description: value });
  };

  const resetButton = () => {
    setFormValues({ ...formValues, phoneNumber: '', addressValue: '' });
  };

  const onChangeSubject = (value) => {
    setFormValues({ ...formValues, title: value });
  };

  const handlePhoneNumber = (nr) => {
    setFormValues({ ...formValues, phoneNumber: nr });
  };

  const handleMeetingAddressValue = (value) => {
    setFormValues({ ...formValues, addressValue: value });
  };

  const handleModal = () => {
    handleOpen();
  };

  const handleClose = () => {
    if (shownModal === 1) {
      setShownModal(0);
    } else {
      setShownModal(0);
      setOpen(false);
    }
  };

  const handleSubmit = async () => {
    let isError = false;
    let newInputErrors = {};

    if (formValues.title.length === 0) {
      isError = true;
      newInputErrors.title = 'Thema erforderlich*';
    }

    if (formValues.description.length === 0) {
      isError = true;
      newInputErrors.description = 'Description erforderlich*';
    }

    //the if below fires showing user error popup that something went wrong

    if (nextStepDate === undefined || nextStepHour === undefined) {
      // setSnackbarMessage(LPTranslate('Error_Server_Down'));
      // setSnackbarSeverity('error');
      // setSnackbarOpen(true);

      isError = true;
      newInputErrors.date = LPTranslate(
        'Error_User_Must_Set_New_Appointment_Date'
      );
    }

    setFormErrors(newInputErrors);

    if (isError) return;

    const descriptionArr = formValues.description.split(/\n/g);

    let gmailDescription,
      outlookDescription,
      yahooDescription,
      icsDescription = '';

    for (let i = 0; i < descriptionArr.length; i++) {
      if (i === 0) {
        gmailDescription = '<div>' + descriptionArr[i] + '</div>\n';
        outlookDescription = '<div>' + descriptionArr[i] + '</div>\n';
        yahooDescription = descriptionArr[i];
        icsDescription = descriptionArr[i];
      } else {
        if (descriptionArr[i] === '') {
          gmailDescription += '<p></p>\n';
          outlookDescription += '<br>';
        } else gmailDescription += '<div>' + descriptionArr[i] + '</div>\n';
        outlookDescription += '<div>' + descriptionArr[i] + '</div>\n';
        yahooDescription += '%0d%0a' + descriptionArr[i];
        icsDescription += '\n' + descriptionArr[i];
      }
    }

    let meetingValString = '';
    if (meetingVal === 0) {
      meetingValString = 'Anruf';
    } else if (meetingVal === 1) {
      meetingValString = 'Vor-Ort Meeting';
    } else if (meetingVal === 2) meetingValString = 'Video Meeting';

    let name;

    if (clientInfoRedux.role === 'lawyer') name = propsData.lawyer_name;
    else if (clientInfoRedux.role === 'client') name = propsData.client_name;

    const hour = nextStepHour.split(':')[0];
    const minute = nextStepHour.split(':')[1];

    const dateString = nextStepDate.split(',')[1].split(' ')[1].split('.');
    const day = dateString[0];
    const month = dateString[1];
    const year = dateString[2];

    const dateMoment = moment(`${day} ${month} ${year}`, 'DD MM YYYY');

    let appointmentDateTimestamp = moment(dateMoment).format('X');

    let startAppointment = moment(dateMoment).set({
      hour: hour,
      minute: minute,
    });
    let endAppointment = moment(dateMoment)
      .set({
        hour: hour,
        minute: minute,
      })
      .add('30', 'minutes');

    let x = moment(startAppointment).format().split('-');
    let rest = x[2].split('+');
    let rest2 = rest[0].split(':');
    let startAppointmentTimestamp = `${x[0]}${x[1]}${rest2[0]}${rest2[1]}${rest2[2]}`;

    x = moment(endAppointment).format().split('-');
    rest = x[2].split('+');
    rest2 = rest[0].split(':');
    let endAppointmentTimestamp = `${x[0]}${x[1]}${rest2[0]}${rest2[1]}${rest2[2]}`;

    let addressValue = '';

    if (formValues.addressValue) {
      addressValue =
        formValues.addressValue.street +
        ' ' +
        formValues.addressValue.house_number +
        ', ' +
        formValues.addressValue.city +
        ', ' +
        formValues.addressValue.zipcode +
        ', ' +
        formValues.addressValue.country;
    }

    try {
      await updateAppointment({
        variables: {
          input: {
            appointment_id: propsData.id,
            lawyer_id: propsData.lawyer_id,
            client_id: propsData.client_id,
            email: clientInfoRedux.email,
            updateAppointment: true,
            appointmentLocation: addressValue,
            gmailDescription: gmailDescription,
            outlookDescription: outlookDescription,
            yahooDescription: yahooDescription,
            icsDescription: JSON.stringify(icsDescription).replace(/"/g, ''),
            meetingVal: meetingValString,
            phoneNumber: formValues.phoneNumber,
            appointmentHour: nextStepHour,
            role: clientInfoRedux.role,
            dayNumber: day,
            month: month,
            year: year,
            nextStepDate: nextStepDate,
            appointmentDateTimestamp: appointmentDateTimestamp,
            startAppointmentTimestamp: startAppointmentTimestamp,
            endAppointmentTimestamp: endAppointmentTimestamp,
            title: formValues.title,
            messageName: name,
            description: formValues.description,
          },
        },
      });
    } catch (error) {
      console.log('aici');
      console.log('error');
      console.log(error);
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    setFormErrors({
      ...formErrors,
      phoneNumber: '',
      addressValue: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingVal]);

  useEffect(() => {
    try {
      if (
        data &&
        data.setAppointment.message ===
          'You have succesfully created a new appointment!'
      ) {
        // let meetingValString = '';
        // if (meetingVal === 0) {
        //   meetingValString = 'Anruf';
        // } else if (meetingVal === 1) {
        //   meetingValString = 'Vor-Ort Meeting';
        // } else if (meetingVal === 2) meetingValString = 'Video Meeting';
        // // de avut grija aici
        // let date = moment(nextStepDate.split(' ')[1], 'DD.MM.YYYY').set({
        //   hour: nextStepHour.split(':')[0],
        //   minute: nextStepHour.split(':')[1],
        // });
        // let dateTimestamp = moment(date).format('X');
        // props.handleUpdateData({
        //   selectedDate: dateTimestamp,
        //   selectedHour: nextStepHour + ':00',
        //   meetingVal: meetingValString,
        // });
        // props.handleUpdateID(parseInt(propsData.id));
        // props.handleFormValuesUpdate(formValues.title, formValues.description);

        // props.resetAppointmentsList();
        setShownModal(2);
      }
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {props.menuButtonSelected === 0 ? (
        <button
          data-cy="suggest-button"
          className={
            styles['suggest-new-appointment-modal-actions-cancel-event-button']
          }
          onClick={handleModal}
        >
          {LPTranslate('Button_Suggest_New_Date')}
        </button>
      ) : (
        <></>
      )}
      {shownModal !== 2 ? (
        <Modal
          className={styles['suggest-new-appointment-modal']}
          open={open}
          onClose={handleClose}
        >
          <Box className={styles['suggest-new-appointment-modal-container']}>
            {shownModal === 0 ? (
              <>
                <div
                  className={styles['suggest-new-appointment-modal-content']}
                >
                  <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={snackbarOpen}
                    autoHideDuration={2000}
                    onClose={handleCloseSnackbar}
                  >
                    <Alert
                      onClose={handleCloseSnackbar}
                      severity={snackbarSeverity}
                      sx={
                        snackbarSeverity === 'error' && {
                          backgroundColor: '#7f56d9 !important',
                          color: 'white',
                        }
                      }
                    >
                      {snackbarMessage}
                    </Alert>
                  </Snackbar>
                  <div
                    className={
                      styles['suggest-new-appointment-modal-supporting-text']
                    }
                  >
                    {appointmentDate +
                      ' / ' +
                      startAppointment.getHours() +
                      ':' +
                      String(startAppointment.getMinutes()).padStart(2, '0') +
                      '-' +
                      endAppointment.getHours() +
                      ':' +
                      String(endAppointment.getMinutes()).padStart(2, '0')}
                  </div>

                  <div
                    className={
                      styles['suggest-new-appointment-modal-heading-and-icon']
                    }
                  >
                    {props.profileImage !== null ? (
                      <img
                        alt="check"
                        style={{ objectFit: 'cover' }}
                        src={props.profileImage}
                        className={
                          styles['suggest-new-appointment-modal-check-icon']
                        }
                      />
                    ) : (
                      <img
                        alt="defaultAvatar"
                        src={defaultAvatar}
                        className={
                          styles['suggest-new-appointment-modal-check-icon']
                        }
                      />
                    )}

                    <div
                      className={
                        styles[
                          'suggest-new-appointment-modal-text-and-supporting-text'
                        ]
                      }
                    >
                      {role === 'client' &&
                        propsData?.specialist_attourneys !== 'null' &&
                        propsData.specialist_attourneys && (
                          <div
                            className={
                              styles[
                                'suggest-new-appointment-modal-area-of-law'
                              ]
                            }
                          >
                            {propsData.specialist_attourneys}
                          </div>
                        )}
                      <div
                        className={
                          styles['suggest-new-appointment-modal-text-name']
                        }
                      >
                        {role === 'client'
                          ? propsData.lawyer_name
                          : propsData.client_name}
                      </div>

                      {role === 'lawyer' && propsData?.client && (
                        <>
                          {propsData?.client.telephone && (
                            <div
                              className={
                                styles[
                                  'suggest-new-appointment-modal-meeting-type'
                                ]
                              }
                            >
                              {propsData?.client.telephone}
                            </div>
                          )}

                          {propsData?.client.email && (
                            <div
                              className={
                                styles[
                                  'suggest-new-appointment-modal-meeting-type'
                                ]
                              }
                            >
                              {propsData?.client.email}
                            </div>
                          )}
                        </>
                      )}

                      <div
                        className={
                          styles['suggest-new-appointment-modal-meeting-type']
                        }
                      >
                        {LPTranslate('LSA_Update_Appointment_Type')}:{' '}
                        {propsData.appointment_type === 'Anruf'
                          ? LPTranslate('LSA_Update_Appointment_Call')
                          : LPTranslate('LSA_Update_Appointment_Site_Meeting')}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      styles[
                        'suggest-new-appointment-modal-textarea-input-field'
                      ]
                    }
                  >
                    <SimpleTextField
                      data_cy="title-input"
                      title={LPTranslate('CSA_Topic_Title')}
                      value={formValues.title}
                      onChange={(value) => {
                        onChangeSubject(value);
                        setFormErrors({
                          ...formErrors,
                          title: '',
                        });
                      }}
                      textFieldWidthPercent={100}
                      type="text"
                      placeholder={LPTranslate('CSA_Topic_Title')}
                      errorMessage={formErrors.title}
                    />

                    <SimpleTextField
                      data_cy="description-input"
                      title={LPTranslate('Common_Message')}
                      value={formValues.description}
                      onChange={(value) => {
                        onChangeDescriptionValue(value);
                        setFormErrors({
                          ...formErrors,
                          description: '',
                        });
                      }}
                      textFieldWidthPercent={100}
                      multiline={true}
                      rows={2.5}
                      type="text"
                      hintText={LPTranslate('CSA_Message_Hint')}
                      placeholder={LPTranslate(
                        'CSA_Create_Appointment_Message_Placeholder'
                      )}
                      errorMessage={formErrors.description}
                      hasHintText
                    />
                  </div>

                  <div
                    className={
                      styles[
                        'suggest-new-appointment-modal-meeting-type-container'
                      ]
                    }
                  >
                    <div
                      className={
                        styles['suggest-new-appointment-modal-date-container']
                      }
                    >
                      <button
                        data-cy="calendar-button"
                        style={{
                          border: formErrors.date ? '1px solid red' : '',
                          boxShadow: formErrors.date ? 'none' : '',
                        }}
                        className={
                          styles[
                            'suggest-new-appointment-modal-selected-calendar-button'
                          ]
                        }
                        onClick={cancelVacation}
                      >
                        <img src={calendarIcon} alt="calendar" />
                        <p
                          className={
                            styles[
                              'suggest-new-appointment-modal-selected-calendar-button-text'
                            ]
                          }
                        >
                          {dateToBeUpdated}
                        </p>
                      </button>

                      {formErrors.date && (
                        <h4
                          className={
                            styles[
                              'suggest-new-appointment-modal-date-error-message'
                            ]
                          }
                        >
                          {formErrors.date}
                        </h4>
                      )}
                    </div>

                    <div
                      className={
                        styles['suggest-new-appointment-modal-radio-buttons']
                      }
                    >
                      <CircleRadioFilter
                        meetingVal={meetingVal}
                        shownModal={shownModal}
                        filterOptions={meetingOptions}
                        resetButton={resetButton}
                        formValues={formValues}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                        handlePhoneNumber={handlePhoneNumber}
                        handleMeetingAddressValue={handleMeetingAddressValue}
                        setMeetingVal={setMeetingVal}
                        availability={props.availability}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={styles['suggest-new-appointment-modal-actions']}
                >
                  <div
                    className={
                      styles['suggest-new-appointment-modal-actions-divider']
                    }
                  />

                  <div
                    className={
                      styles['suggest-new-appointment-modal-actions-content']
                    }
                  >
                    <button
                      data-cy="cancel-button"
                      className={
                        styles['suggest-new-appointment-modal-cancel-button']
                      }
                      onClick={handleClose}
                    >
                      {LPTranslate('Button_Cancel')}
                    </button>

                    <button
                      data-cy="submit-update-button"
                      className={
                        styles['suggest-new-appointment-modal-success-button']
                      }
                      onClick={handleSubmit}
                    >
                      {LPTranslate('Button_Save')}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <SetMeetingCalendarModal
                shownModal={shownModal}
                handleClose={handleClose}
                lawyer_id={propsData.lawyer_id}
                handleShownModal={handleShownModal}
              />
            )}
          </Box>
        </Modal>
      ) : (
        <Modal
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px',
            width: '100%',
            height: '100%',
          }}
          open={open}
          onClose={handleClose}
        >
          <UpdatePasswordConfirmationModal
            handleClose={() => {
              props.handleRefetch();
              handleClose();
            }}
            titleMessage={LPTranslate('CSA_Update_Appointment_Confirm_Title')}
            hintMessage={LPTranslate('CSA_Update_Appointment_Confirm_Hint')}
            buttonText={LPTranslate('CSA_Update_Appointment_Confirm_Button')}
          />
        </Modal>
      )}
    </>
  );
};
export default SuggestNewAppointmentModal;
