import React from 'react';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import backgroundImage from '../Assets/pictures/not-found-page-background.jpeg';

const ErrorPage = () => {
  return (
    <>
      <div
        style={{
          width: '100%',
          background: `url("${backgroundImage}") no-repeat center center`,
        }}
      >
        <div
          style={{
            width: '80%',
            maxWidth: '1080px',
            margin: 'auto',
            height: '100vh',
            padding: '4% 0',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '250px',
          }}
        >
          <h2
            style={{
              paddingBottom: '10px',
              lineHeight: '1em',
              fontWeight: '500',
              color: '#FFFFFF',
              fontSize: 36,
              margin: 0,
              WebkitFontSmoothing: 'antialiased',
            }}
          >
            Ups...
          </h2>

          <p
            style={{
              paddingBottom: 0,
              margin: 0,
              color: '#FFFFFF',
              fontSize: '16px',
              WebkitFontSmoothing: 'antialiased',
            }}
          >
            {LPTranslate('Error_404')}
          </p>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
