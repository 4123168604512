const convertParagraphToHeader4 = (inputHtml) => {
  if(!inputHtml) return inputHtml;
  
  const header4Regex = /<p>/g;
  const header4Replacement = '<h4>';
  const outputHtml = inputHtml.replace(header4Regex, header4Replacement);
  return outputHtml;
};

export { convertParagraphToHeader4 };
