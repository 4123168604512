import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { SET_PASSWORD_ON_REGISTER } from 'Services/Queries/user';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { validatePassword } from 'Services/Utils/validations';

import styles from 'Assets/styles/registerLawyer/SetPasswordOnRegister.module.scss';
import key from 'Assets/pictures/keyIcon.svg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SetPasswordOnRegister = (props) => {
  const { lawyerRegisterRedux, handleNext, handleChangePassword } = props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const [setPasswordOnRegister, { data: setPasswordOnRegisterData }] =
    useMutation(SET_PASSWORD_ON_REGISTER);

  const [formValues, setFormValues] = useState({
    password: '',
    repeatPassword: '',
  });
  const [formErrors, setFormErrors] = useState({
    password: '',
    repeatPassword: '',
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'password':
        setFormValues({ ...formValues, password: value });
        break;
      case 'repeatPassword':
        setFormValues({ ...formValues, repeatPassword: value });
        break;

      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = {};

    if (formValues.password.length === 0) {
      isError = true;
      newInputErrors.password = LPTranslate('Error_Password_Required');
    } else if (formValues.password.length < 8) {
      isError = true;
      newInputErrors.password = LPTranslate('Error_Password_Min_Length');
    }

    if (!validatePassword(formValues.password)) {
      isError = true;
      newInputErrors.password = LPTranslate(
        'Error_Password_Invalid_Requirements'
      );
    }

    if (formValues.repeatPassword.length === 0) {
      isError = true;
      newInputErrors.repeatPassword = LPTranslate(
        'Error_Confirm_Password_Required'
      );
    } else if (formValues.repeatPassword.length < 8) {
      isError = true;
      newInputErrors.repeatPassword = LPTranslate('Error_Password_Min_Length');
    } else if (formValues.repeatPassword !== formValues.password) {
      isError = true;
      newInputErrors.repeatPassword = LPTranslate('Error_Passwords_Dont_Match');
    }

    setFormErrors(newInputErrors);
    if (isError) return;

    try {
      await setPasswordOnRegister({
        variables: {
          input: {
            email: lawyerRegisterRedux.userEmail,
            password: formValues.password,
          },
        },
      });
    } catch (error) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (setPasswordOnRegisterData) {
      if (setPasswordOnRegisterData.setPasswordOnRegister.message) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'lawyer-register-set-password-custom-event',
        });
        handleChangePassword(formValues.password);
        handleNext(8);
      } else {
        setSnackbarMessage(LPTranslate('Error_Server_Down'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPasswordOnRegisterData]);

  return (
    <>
      <div className={styles.emailCheckerSection}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={
              snackbarSeverity === 'error' && {
                backgroundColor: '#7f56d9 !important',
              }
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <div className={styles.emailCheckerContainer}>
          <div className={styles.frame2}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.featuredIcon}>
                  <div className={styles.key}>
                    <img src={key} alt="key" />
                  </div>
                </div>

                <div className={styles.textAndSupportingText}>
                  <p className={styles.text}>
                    {LPTranslate('RC_Page_5_Title')}
                  </p>

                  <p className={styles.supportingText}>
                    {LPTranslate('RC_Page_5_Description')}
                  </p>
                </div>
              </div>

              <form
                className={styles.formContent}
                id="setPassword-form"
                onSubmit={handleSubmit}
              >
                <div className={styles.passwordInput}>
                  <div className={styles.inputWithLabel}>
                    <SimpleTextField
                      data_cy="password-input"
                      title={LPTranslate('Input_Password')}
                      placeholder="••••••••"
                      onChange={(value) => {
                        handleInputChange('password', value);
                        setFormErrors({
                          ...formErrors,
                          password: '',
                        });
                      }}
                      value={formValues.password}
                      type="password"
                      errorMessage={formErrors.password}
                    />
                  </div>
                </div>

                <div className={styles.repeatPasswordInput}>
                  <div className={styles.inputWithLabel}>
                    <SimpleTextField
                      data_cy="repeat-password-input"
                      title={LPTranslate('Input_Repeat_Password')}
                      placeholder="••••••••"
                      onChange={(value) => {
                        handleInputChange('repeatPassword', value);
                        setFormErrors({
                          ...formErrors,
                          repeatPassword: '',
                        });
                      }}
                      value={formValues.repeatPassword}
                      type="password"
                      errorMessage={formErrors.repeatPassword}
                    />
                  </div>
                </div>

                <div className={styles.setPasswordButtonContainer}>
                  <button
                    data-cy="submit-button"
                    className={styles.setPasswordButtonBase}
                    type="submit"
                    form="setPassword-form"
                  >
                    <p className={styles.setPasswordButtonText}>
                      {LPTranslate('RC_Page_5_Email_Verified_Set_Password_Now')}
                    </p>
                  </button>
                </div>
              </form>
              {localStorage.getItem('subscriptionPlan') === 'Silver' && (
                <Button
                  className={
                    styles[
                    'lawp-set-password-on-register-page-back-to-subscription'
                    ]
                  }
                  data-cy="back-button"
                  onClick={(e) => {
                    props.handleBackOneStep();
                  }}
                  startIcon={<ArrowBackRoundedIcon />}
                >
                  {LPTranslate('RL_Back_To_Subscription')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetPasswordOnRegister;
