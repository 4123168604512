import * as React from 'react';

import { useQuery } from '@apollo/client';

import CircularProgress from '@mui/material/CircularProgress';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { GET_LAWYERS_TOP_CATEGORIES } from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/LawyerCategories.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const LawyerCategories = ({ sitemaps }) => {
  const [dataSitemaps, setDataSitemaps] = React.useState(sitemaps || null);
  const [sitemapUsed, setSitemapUsed] = React.useState(true);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const { data, loading } = useQuery(GET_LAWYERS_TOP_CATEGORIES, {
    onCompleted: (data) => {},
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  React.useEffect(() => {
    if (!sitemaps && data) {
      let object = {
        cities: data.getLawyersTopCategories.cities,
        areasOfLaw: data.getLawyersTopCategories.areasOfLaw,
        specialities: data.getLawyersTopCategories.specialities,
        articles: data.getLawyersTopCategories.articles,
      };

      setDataSitemaps(object);
      setSitemapUsed(false);
    }
  }, [data, sitemaps]);

  return (
    <div className="lawp-lawyer-categories-container">
      <div className="content-container">
        <h2>{LPTranslate('Common_Categories')}</h2>

        <Box sx={{ width: '100%' }}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
              className="lawp-lawyer-categories-tabs"
            >
              <Tab
                label={LPTranslate('Common_Locations')}
                {...a11yProps(0)}
                disableRipple={true}
              />
              <Tab
                label={LPTranslate('Common_Specialities')}
                {...a11yProps(1)}
                disableRipple={true}
              />
              <Tab
                label={LPTranslate('Common_Areas_Of_Law')}
                {...a11yProps(2)}
                disableRipple={true}
              />
              <Tab
                label={LPTranslate('Common_Legal_Informations')}
                {...a11yProps(3)}
                disableRipple={true}
              />
            </Tabs>
          </AppBar>

          {dataSitemaps && (
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <ul className="multi-column-list">
                  {dataSitemaps.cities.map((city, index) => (
                    <li key={index}>
                      <a
                        href={`${
                          sitemapUsed
                            ? city
                            : process.env.REACT_APP_DNS_URI + '/ort/' + city
                        }`}
                      >
                        {city}
                      </a>
                    </li>
                  ))}
                </ul>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <ul className="multi-column-list">
                  {dataSitemaps.areasOfLaw.map((areaOfLaw, index) => (
                    <li key={index}>
                      <a
                        href={`${
                          sitemapUsed
                            ? areaOfLaw
                            : process.env.REACT_APP_DNS_URI +
                              '/aol/' +
                              areaOfLaw.replace(/\s+/g, '-')
                        }`}
                      >
                        {areaOfLaw}
                      </a>
                    </li>
                  ))}
                </ul>
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <ul className="multi-column-list">
                  {dataSitemaps.specialities.map((speciality, index) => (
                    <li key={index}>
                      <a
                        href={`${
                          sitemapUsed
                            ? speciality
                            : process.env.REACT_APP_DNS_URI +
                              '/aol/' +
                              speciality.replace(/\s+/g, '-')
                        }`}
                      >
                        {speciality}
                      </a>
                    </li>
                  ))}
                </ul>
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <ul className="multi-column-list">
                  {dataSitemaps.articles.map((article, index) => (
                    <li key={index}>
                      <a
                        href={`${
                          sitemapUsed
                            ? article.url
                            : process.env.REACT_APP_DNS_URI +
                              '/rechtsinfos/' +
                              article.id
                        }`}
                      >
                        {article.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </TabPanel>
            </SwipeableViews>
          )}

          {loading && (
            <div className="loading-container">
              <CircularProgress color="secondary" />
            </div>
          )}
        </Box>

        <a
          href={`${process.env.REACT_APP_DNS_URI}/legal-directory`}
          className="more-link"
        >
          {LPTranslate('Common_More')}...
        </a>
      </div>
    </div>
  );
};

export default LawyerCategories;
