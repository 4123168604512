import { gql } from '@apollo/client';

export const GET_LAWYERS = gql`
  {
    lawyers {
      id
      salutation
      title
      first_name
      last_name
      title_suffix
      job_title
      lawfirm_name
      street
      postcode
      location
      country
      telephone
      fax_number
      email
      emailalt
      website
      specialist_attourneys
      short_description
      self_description
      self_lawfirm_description
      rating
      user_id
      subscription
      lawyerAvailability {
        show_availability
        personally
        by_phone
        online_meeting
      }
      areasOfLaw
      reviews {
        id
        client_name
        lawyer_name
        rating
        content
        client_id
        lawyer_id
        comments {
          name
          content
          lawyer_id
          review_id
        }
      }
      reviewNr
    }
  }
`;
export const GET_LAWYER = gql`
  query GetLawyer($id: ID!) {
    lawyer(id: $id) {
      ... on Lawyer {
        id
        salutation
        title
        first_name
        last_name
        title_suffix
        job_title
        lawfirm_name
        street
        house_number
        postcode
        location
        street
        house_number
        country
        telephone
        fax_number
        email
        emailalt
        website
        specialist_attourneys
        short_description
        self_description
        self_lawfirm_description
        rating
        user_id
        subscription
        areasOfLaw
        specialities
        languages {
          label
          iso
        }
        profileImage
        review_count
        lawyerImages
        lawyerAvailability {
          show_availability
          personally
          by_phone
          online_meeting
        }
        subscription_type
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const GET_LAWYER_AT_REGISTER = gql`
  query getLawyerAtRegister($email: String) {
    getLawyerAtRegister(email: $email) {
      first_name
      last_name
      profileImage
    }
  }
`;

export const GET_LAWYER_BY_USERID = gql`
  query GetLawyerByUserId($user_id: Int!) {
    lawyerByUserId(user_id: $user_id) {
      id
      first_name
      name
    }
  }
`;

export const GET_AREAS_OF_LAW = gql`
  query getAllAreasOfLaw {
    getAreasOfLaw {
      id
      name
    }
  }
`;

export const GET_SPECIALITIES = gql`
  query getSpecialities {
    getSpecialities {
      id
      speciality
    }
  }
`;

export const GET_REVIEWS_OF_CLIENT = gql`
  query GetReviewOfClient($client_id: ID!) {
    reviewsOfClient(client_id: $client_id) {
      id
      client_name
      lawyer_name
      rating
      content
      client_id
      lawyer_id
      comments {
        name
        content
        lawyer_id
        review_id
      }
    }
  }
`;

export const ADD_APOINTMENT = gql`
  mutation AddApointment($input: CreateApointmentInput!) {
    addApointment(input: $input) {
      ... on Apointment {
        id
        client_name
        lawyer_name
        date
        time
        lawyer_id
        client_id
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const ADD_ARTICLE = gql`
  mutation AddArticle($input: CreateArticleInput!) {
    addArticle(input: $input) {
      ... on Article {
        id
        user_id
        title
        description
        content
        areas_of_law
        cover_image
        author_name
        # images
        link
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const UPDATE_ARTICLE = gql`
  mutation UpdateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      ... on Article {
        id
        user_id
        title
        description
        content
        areas_of_law
        cover_image
        # images
        link
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const DELETE_ARTICLE = gql`
  mutation DeleteArticle($input: ID!) {
    deleteArticle(id: $input) {
      ... on DeletedArticle {
        id
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const GET_REVIEW_REPORTS_BY = gql`
  query getReviewReportsBy($searchBy: String!, $getReviewReportsById: ID!) {
    getReviewReportsBy(searchBy: $searchBy, id: $getReviewReportsById) {
      id
      review_id
      user_id
      lawyer_id
      report_message
      createdAt
    }
  }
`;

export const REGISTER_LAWYER_2 = gql`
  mutation registerLawyer($input: RegisterLawyerInput!) {
    registerLawyer(input: $input) {
      user_email
    }
  }
`;

export const GET_AVAILABLE_LAWYERS = gql`
  mutation getAvailableLawyers($email: String) {
    getAvailableLawyers(email: $email) {
      id
      title
      title_suffix
      first_name
      last_name
      location
      street
      telephone
      country
      specialist_attourneys
      picture
      house_number
      postcode
      areasOfLaw
      specialities
    }
  }
`;
export const CHECK_IF_LAWYER_EXISTS = gql`
  mutation CheckLawyer($email: String) {
    checkIfLawyerExists(email: $email) {
      message
      error
    }
  }
`;

export const REGISTER_LAWYER = gql`
  mutation createNewLawyer(
    $firstName: String
    $lastName: String
    $email: String
  ) {
    createNewLawyer(firstName: $firstName, lastName: $lastName, email: $email) {
      message
      status
    }
  }
`;

export const CHANGE_USER_LAWYER_EMAIL = gql`
  mutation changeUserLawyerEmail($input: ChangeUserLawyerEmailInput!) {
    changeUserLawyerEmail(input: $input) {
      message
      status
    }
  }
`;

export const SEND_EMAIL_TO_LAWFIRM_EMAIL = gql`
  mutation sendEmailToLawFirmEmail($input: SendEmailToLawFirmEmailInput!) {
    sendEmailToLawFirmEmail(input: $input) {
      message
      error
    }
  }
`;
export const UPDATE_LAWYER_USER_ID = gql`
  mutation updateLawyerUserId($email: String!) {
    updateLawyerUserId(email: $email) {
      message
      error
    }
  }
`;

export const DELETE_LAWYER_FROM_REGISTER = gql`
  mutation deleteUserFromRegister($email: String!) {
    deleteUserFromRegister(email: $email) {
      message
      error
    }
  }
`;

export const GET_REVIEW_REPORTS = gql`
  query GetReviewReports {
    getReviewReports {
      id
      lawyer_id
      user_id
      review_id
      report_message
    }
  }
`;

export const UPDATE_LAWYER = gql`
  mutation UpdateLawyer($input: UpdateLawyerInput!) {
    updateLawyer(input: $input) {
      ... on Lawyer {
        salutation
        title
        first_name
        last_name
        title_suffix
        job_title
        lawfirm_name
        street
        house_number
        postcode
        location
        country
        telephone
        fax_number
        email
        emailalt
        website
        specialist_attourneys
        short_description
        self_description
        self_lawfirm_description
        rating
        user_id
        subscription
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const VERIFY_LAWYER = gql`
  mutation verifyRegistrationAccount($token: String!) {
    verifyRegistrationAccount(token: $token) {
      message
      error
    }
  }
`;

export const VERIFY_IF_LAWYER_UPDATED_EMAIL = gql`
  mutation verifyIfLawyerUpdatedEmail($email: String!) {
    verifyIfLawyerUpdatedEmail(email: $email) {
      newEmail
    }
  }
`;

export const CONFIRM_EMAIL_ADRESS_UPDATE = gql`
  mutation sendEmailConfirmationToUpdateEmail(
    $firstName: String!
    $lastName: String!
    $actualEmail: String!
    $newEmail: String!
    $password: String!
  ) {
    sendEmailConfirmationToUpdateEmail(
      firstName: $firstName
      lastName: $lastName
      actualEmail: $actualEmail
      newEmail: $newEmail
      password: $password
    ) {
      message
      error
    }
  }
`;

export const UPDATE_LAWYER_EMAIL_ADDRESS = gql`
  mutation updateLawyerEmailAddress($actualEmail: String!, $newEmail: String!) {
    updateLawyerEmailAddress(actualEmail: $actualEmail, newEmail: $newEmail) {
      message
      error
    }
  }
`;

export const RESEND_VERIFY_EMAIL = gql`
  mutation resendVerifyEmail($email: String!) {
    resendVerifyEmail(email: $email) {
      message
      error
    }
  }
`;

export const ADD_LAWYER = gql`
  mutation AddLawyer($input: CreateLawyerInput!) {
    createLawyer(input: $input) {
      ... on Lawyer {
        first_name
        last_name
        email
        emailalt
        user_id
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const SEARCH_LAWYERS = gql`
  query SearchLawyers(
    $search: String!
    $cityFilter: [String!]
    $onlineApointmentFilter: [String!]
    $areaOfLawFilter: [Int!]
    $ratingSort: Int!
    $minScore: Int!
  ) {
    searchLawyers(
      search: $search
      cityFilter: $cityFilter
      onlineApointmentFilter: $onlineApointmentFilter
      areaOfLawFilter: $areaOfLawFilter
      ratingSort: $ratingSort
      minScore: $minScore
    ) {
      id
      salutation
      title
      attorney
      first_name
      name
      title_suffix
      professional_title
      office_name
      street
      postcode
      location
      country
      postofficebox
      telephoneint
      phoneart
      faxint
      email
      emailalt
      website
      specialistattorneys
      areasoflaw
      rating
      subscription
      reviews {
        id
        client_name
        lawyer_name
        rating
        content
        client_id
        lawyer_id
        comments {
          name
          content
          lawyer_id
          review_id
        }
      }
      reviewNr
    }
  }
`;

export const SEARCH_L = gql`
  query SearchLawyerInput($input: SearchLawyerInput!) {
    searchL(input: $input) {
      lawyers {
        id
        title
        title_suffix
        first_name
        last_name
        location
        street
        telephone
        country
        email
        specialist_attourneys
        rating
        subscription
        picture
        house_number
        postcode
        areasOfLaw
        specialities
        reviewNr
        lawyerAvailability {
          show_availability
          personally
          by_phone
          online_meeting
        }
        isFavorite
        short_description
      }
      status
      number_of_pages
    }
  }
`;

export const UPGRADE_PLAN = gql`
  mutation upgradePlan($id: ID!, $subscription: Int!) {
    upgradePlan(id: $id, subscription: $subscription) {
      message
      error
    }
  }
`;

export const GET_ALL_ARTICLES = gql`
  query GetAllArticles($input: GetAllArticlesInput!) {
    articles(input: $input) {
      articles {
        id
        user_id
        author_name
        title
        description
        content
        areas_of_law
        cover_image
        # images
        link
        created_at
        updated_at
      }
      status
      number_of_pages
    }
  }
`;

export const GET_ALL_LAWYER_ARTICLES = gql`
  query GetAllLawyerArticles($input: GetAllArticlesInput!) {
    articles(input: $input) {
      articles {
        id
        lawyer_id
        lawyer_name
        title
        description
        content
        areas_of_law
        cover_image
        # images
        link
        created_at
        updated_at
      }
      status
      number_of_pages
    }
  }
`;

export const GET_ARTICLE = gql`
  query GetArticle($id: String!) {
    article(id: $id) {
      ... on Article {
        id
        author_name
        lawyer_title
        lawyer_first_name
        lawyer_last_name
        role
        lawyer_id
        title
        description
        content
        user_id
        link
        cover_image
        lawyer_profile_picture
        lawyer_lawfirm
        areas_of_law
        created_at
        updated_at
        # images
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const GET_ARTICLES_OF_LAWYER = gql`
  query GetReviewOfLawyer($input: GetArticlesOfLawyerInput!) {
    articlesOfLawyer(input: $input) {
      articles {
        id
        author_name
        user_id
        title
        description
        content
        areas_of_law
        cover_image
        # images
        link
        created_at
        updated_at
      }
      status
      number_of_pages
    }
  }
`;

export const ADD_REVIEW_REPORT = gql`
  mutation CreateReviewReport($input: ReviewReportInput!) {
    createReviewReport(input: $input) {
      error
      message
    }
  }
`;

export const DELETE_REVIEW_REPORT = gql`
  mutation DeleteReviewReport($deleteReviewReportId: ID!) {
    deleteReviewReport(id: $deleteReviewReportId) {
      ... on Review {
        client_name
        lawyer_name
        content
        rating
        client_id
        lawyer_id
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const GET_REVIEW_REPORT = gql`
  query GetReviewReport($id: ID!) {
    getReviewReport(id: $id) {
      ... on ReviewReport {
        report_reason
        report_message
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const ADD_PROFILE_PICTURE = gql`
  mutation CreateProfilePicture($input: ProfilePictureInput!) {
    createProfilePicture(input: $input) {
      id
      image_type
      image_data
      lawyer_id
    }
  }
`;
export const GET_PROFILE_PICTURE = gql`
  query getProfilePicture($image_type: String!, $id: ID!) {
    getProfilePicture(image_type: $image_type, id: $id) {
      image_data
    }
  }
`;

export const GET_REVIEWS_OF_LAWYER = gql`
  query GetReviewsOfLawyer($input: GetReviewsWithPaginationInput!) {
    reviewsOfLawyer(input: $input) {
      reviews {
        id
        client_name
        lawyer_name
        title
        content
        rating
        lawyer_id
        client_id
        createdAt
        client {
          id
          name
          profileImage
        }
        response
      }
      status
      number_of_pages
    }
  }
`;

export const ADD_LAWYER_AVAILABILITY_TIME_INTERVAL = gql`
  mutation CreateLawyerAvailabilityTimeInterval(
    $input: LawyerAvailabilityTimeIntervalInput!
  ) {
    createLawyerAvailabilityTimeInterval(input: $input) {
      status
      message
    }
  }
`;

export const ADD_LAWYER_HOLIDAYS = gql`
  mutation CreateLawyerHolidays($input: LawyerHolidaysInput!) {
    createLawyerHolidays(input: $input) {
      status
      message
    }
  }
`;

export const GET_LAWYER_AVAILABILITY_TIME_INTERVAL = gql`
  query GetLawyerAvailabilityTimeInterval($id: ID!) {
    getLawyerAvailabilityTimeInterval(id: $id) {
      days {
        day
        enabled
        intervals {
          start
          end
        }
      }
    }
  }
`;

export const GET_LAWYER_AVAILABILITY = gql`
  query getLawyerScheduleAvailability($id: ID!) {
    getLawyerScheduleAvailability(id: $id) {
      show_availability
      personally
      by_phone
    }
  }
`;

export const GET_LAWYER_HOLIDAYS = gql`
  query getLawyerHolidaysForSettings($id: ID!) {
    getLawyerHolidaysForSettings(id: $id) {
      holidays {
        start_date_timestamp
        end_date_timestamp
      }
    }
  }
`;

export const GET_LAWYERS_BY_SCORE = gql`
  query GetLawyersByScore($input: LawyersByScoreInput!) {
    lawyersByScore(input: $input) {
      id
      first_name
      last_name
      short_description
      specialist_attourneys
      profileImage
    }
  }
`;

export const GET_PREVIEW_REVIEWS = gql`
  query GetPreviewReviews {
    getPreviewReviews {
      id
      lawyer_name
      title
      content
      rating
      lawyer_image
    }
  }
`;

export const GET_LAWYERS_FOR_LAWYER_SECTION = gql`
  query GetLawyersForLawyerSection {
    getLawyersForLawyerSection {
      id
      first_name
      title
      last_name
      lawfirm_name
      specialist_attourneys
      lawyer_image
      rating
    }
  }
`;

export const CREATE_REVIEW_RESPONSE = gql`
  mutation CreateReviewResponse($input: CreateReviewResponseInput!) {
    createReviewResponse(input: $input) {
      error
      message
    }
  }
`;

export const GET_REVIEW_RESPONSE = gql`
  query GetReviewResponse($id: ID!) {
    getReviewResponse(id: $id) {
      ... on ReviewResponse {
        response_message
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const ACTIVE_APPOINTMENTS_COUNT = gql`
  query ActiveAppointmentsCount {
    activeAppointmentsCount
  }
`;

export const UPDATE_LAWYER_ADDRESS_ON_REGISTER = gql`
  mutation updateLawyerAddressOnRegister(
    $input: UpdateLawyerAddressOnRegisterInput!
  ) {
    updateLawyerAddressOnRegister(input: $input) {
      message
      error
    }
  }
`;

export const GET_CLAIMED_LAWYER_ADDRESS_ON_REGISTER = gql`
  query getClaimedLawyerAddressOnRegister(
    $input: getClaimedLawyerAddressOnRegisterInput!
  ) {
    getClaimedLawyerAddressOnRegister(input: $input) {
      street
      house_number
      postcode
      location
      country
      telephone
    }
  }
`;

export const DELETE_LAWYER_REGISTER_LINK_UUID = gql`
  mutation deleteLawyerRegisterLinkUUID($token: String!) {
    deleteLawyerRegisterLinkUUID(token: $token) {
      message
      error
    }
  }
`;

export const GET_LAWYERS_TOP_CATEGORIES = gql`
  query getLawyersTopCategories {
    getLawyersTopCategories {
      cities
      areasOfLaw
      specialities
      articles {
        id
        title
      }
    }
  }
`;
