import React, { useState, useEffect } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import DeleteAccountPage from 'Pages/DeleteAccountPage';
import DeleteModalForGreatTier from 'Components/Modals/DeleteModalForGreatTier';
import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import EditSaveChangesSection from 'Components/Lawyer/Edit/EditSaveChangesSection';
import UpdateLawyerDetailsSection from 'Components/Lawyer/UpdateDetails/UpdateLawyerDetailsSection';
import FinishUpdateLawyerDetails from 'Components/Lawyer/UpdateDetails/FinishUpdateLawyerDetails';

import { VERIFY_PARAM_TOKEN_MUTATION } from 'Services/Queries/user';
import { UPDATE_LAWYER_EMAIL_ADDRESS } from 'Services/Queries/lawyer';
import { parseJwt } from 'Services/Utils/token';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/updateLawyerDetails/UpdateLawyerDetailsPage.module.scss';

const UpdateLawyerDetailsPage = () => {
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const [updateLawyerEmailAddress] = useMutation(UPDATE_LAWYER_EMAIL_ADDRESS);
  const [verifyParamToken, { data, loading }] = useMutation(
    VERIFY_PARAM_TOKEN_MUTATION
  );
  const { token } = useParams();

  const navigate = useNavigate();
  const [tokenAccepted, setTokenAccepted] = useState(false);
  const [stepState, setStepState] = useState(0);
  const [subscription, setSubscription] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (parseJwt(clientInfoRedux.token).subscription === 2) {
      setSubscription(LPTranslate('Common2_Gold_Plan'));
    } else if (parseJwt(clientInfoRedux.token).subscription === 3)
      setSubscription(LPTranslate('Common2_Platin_Plan'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      try {
        const verifyParamTokenFunction = async () => {
          await verifyParamToken({
            variables: { input: { token: token, type: 'update details' } },
          });
        };

        verifyParamTokenFunction();
      } catch (error) {
        console.log(error);
      }
    }
  }, [token, verifyParamToken]);

  useEffect(() => {
    if (data && data.verifyParamTokenMutation) {
      if (data.verifyParamTokenMutation.status === 'error') {
        alert(data.verifyParamTokenMutation.message);
        navigate('/updateLawyerDetails');
      } else if (data.verifyParamTokenMutation.message === 'Token accepted') {
        try {
          const update = async () => {
            let lawyer = await updateLawyerEmailAddress({
              variables: {
                actualEmail: parseJwt(token).actualEmail,
                newEmail: parseJwt(token).newEmail,
              },
            });

            if (lawyer.data.updateLawyerEmailAddress.message) {
              setTokenAccepted(true);
              setStepState(1);
            } else if (lawyer.data.updateLawyerEmailAddress.error) {
              alert(lawyer.data.updateLawyerEmailAddress.error);
            }
          };

          update();
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, [data, loading, navigate, token, updateLawyerEmailAddress]);

  return (
    <>
      {stepState === 0 && (
        <div className={styles['container']}>
          <div className={styles['edit-lawyer-top-menu']}>
            <SettingsMenu
              setUpdateComponent={'/updateLawyerPage'}
              role={clientInfoRedux.role}
              subscription={parseJwt(clientInfoRedux.token).subscription}
            />
          </div>
          <div className={styles.EditSaveChangesSection}>
            <EditSaveChangesSection
              pageTitle={LPTranslate('SIS_Account_Title')}
            />
          </div>

          <UpdateLawyerDetailsSection
            setStepState={setStepState}
            setOpenModal={setOpenModal}
          />
        </div>
      )}

      {stepState === 1 && (
        <FinishUpdateLawyerDetails
          tokenAccepted={tokenAccepted}
          token={token}
        />
      )}

      <DeleteModalForGreatTier
        subscription={subscription}
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
      />

      {stepState === 2 && <DeleteAccountPage setStepState={setStepState} />}
    </>
  );
};

export default UpdateLawyerDetailsPage;
