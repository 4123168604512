import React from 'react';

import { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import { GET_ARTICLE } from 'Services/Queries/lawyer';
import { milisecondsToDDMMYYYY } from 'Services/Utils/moment';
import { AreasOfLaw_Color } from 'Services/Utils/areaOfLaws';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import MetaDecorator from 'Layouts/MetaDecorator';
import { readingTime } from 'Services/Utils/readingTime';
import { convertParagraphToHeader4 } from 'Services/Utils/convertParagraphToHeader4';
import { isBrowser } from 'Services/Utils/ssr';

import ArticleGrayFooter from 'Components/Articles/ArticleGrayFooter.js';
import ArticleLawyerCard from 'Components/Articles/ArticleLawyerCard.js';

import DefaultAvatar from 'Assets/pictures/AvatarProfile.png';
import 'Assets/styles/articles/ArticleBlogPageDetails.scss';
import 'Assets/styles/articles/ArticleBlog.scss';

import 'Assets/quill.css';

const ArticleBlogPageDetails = (props) => {
  const [ReactQuill, setReactQuill] = useState(null);

  useEffect(() => {
    if (isBrowser()) {
      const loadQuill = async () => {
        const Quill = await import('react-quill');
        setReactQuill(() => Quill.default);
      };
      loadQuill();
    }
  }, []);

  useEffect(() => {
    if (ReactQuill) {
      // ssr might not correctly apply the font sizes. This is a workaround:
      // go to Assets/quill.css and change the ql-container h5-4-3-2-1 accordingly;

      let SizeStyle = ReactQuill.Quill.import('attributors/style/size');
      SizeStyle.whitelist = ['18px', '22px', '26px', '14px'];
      ReactQuill.Quill.register(SizeStyle, true);
    }
  }, [ReactQuill]);

  const params = useParams();
  const slug = params.articleSlug.split('-');
  let id = slug[slug.length - 1];

  const navigate = useNavigate();

  const articleData = useQuery(GET_ARTICLE, {
    variables: { id: id },
  });

  const [article, setArticle] = useState('');
  const [articleAreas, setArticleAreas] = useState();

  const [articleImage, setArticleImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  const ArticleBackButtonText = () => {
    if (!isBrowser()) return '';

    if (
      localStorage.getItem('ArticleBlogPageDetailsPreviousPage') ===
      'LawyerPageArticleView'
    ) {
      return LPTranslate('Button_Back_To_Lawyer_Articles');
    } else if (
      localStorage.getItem('ArticleBlogPageDetailsPreviousPage') ===
      'LawyerPage'
    ) {
      return LPTranslate('Button_Back_To_Lawyer_Page');
    } else if (
      localStorage.getItem('ArticleBlogPageDetailsPreviousPage') === 'Home'
    ) {
      return LPTranslate('Button_Back');
    } else if (
      localStorage.getItem('ArticleBlogPageDetailsPreviousPage') ===
      'LawyerSettings'
    ) {
      return LPTranslate('Button_Back_To_Lawyer_Settings');
    } else {
      return LPTranslate('Button_Back_To_Articles');
    }
  };

  useEffect(() => {
    if (!isBrowser()) return;

    return () => {
      if (
        localStorage.getItem('ArticleBlogPageDetailsPreviousPage') ===
        'LawyerPageArticleView'
      ) {
        localStorage.setItem('LawyerPageOpenArticleView', true);
        localStorage.setItem('LawyerPageOpenArticleViewTimeBack', Date.now());
      }
    };
  }, []);

  useEffect(() => {
    if (!articleData.loading) {
      if (articleData.data.article.status === 'error') {
        navigate('/*');
        return;
      }

      setArticle(articleData.data.article);
      setArticleAreas(articleData.data.article.areas_of_law);
      setArticleImage(articleData.data.article.cover_image);

      if (
        articleData.data.article.lawyer_profile_picture !==
          'no avatar image found' &&
        articleData.data.article.lawyer_profile_picture
      ) {
        setProfileImage(articleData.data.article.lawyer_profile_picture);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleData.data]);

  if (articleData.loading || articleData.data === undefined) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '300px',
            marginBottom: '200px',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }
  if (articleData.error) {
    return <p>error...</p>;
  }

  const AreaOfLawTagJSX = (value, key) => {
    return (
      <div
        className="lawp-add-lawyer-review-modal-area-of-law-tag-container"
        style={{
          display: 'inline-block',
          color: AreasOfLaw_Color[value]?.color || 'black',
          backgroundColor: AreasOfLaw_Color[value]?.background || 'white',
        }}
        onClick={(e) => {
          e.stopPropagation();

          let aol = value;

          aol = aol.replace(/\s+/g, '-');

          navigate(`/aol/${aol}`);
        }}
        key={key}
      >
        <span className="lawp-add-lawyer-review-modal-area-of-law-tag-text">
          {value}
        </span>
      </div>
    );
  };

  return (
    articleData.loading === false && (
      <div className="lawp-article-blog-page-details-blog-page">
        <MetaDecorator
          title={article.title ? article.title : 'title'}
          description={
            article.description ? article.description : 'description'
          }
          canonical={'canonical'}
          link={isBrowser() ? window.location.href : 'canonical'}
        />

        <div className="blog-page-header-container">
          <div className="lawp-article-blog-page-details-to-lawyer-profile-button-container">
            <Button
              className="lawp-article-blog-page-details-to-lawyer-profile-button"
              data-cy="back-button"
              onClick={(e) => {
                navigate(-1);
              }}
              startIcon={<ArrowBackRoundedIcon />}
            >
              {ArticleBackButtonText()}
            </Button>
          </div>

          <div className="blog-page-header-container-title">
            <div className="blog-page-header-container-title-content">
              <div className="title-content-header-support">
                <div className="content-header-subhead">
                  <div className="content-subhead">
                    {article.updated_at?.length !== 0 ? (
                      <div>
                        {LPTranslate('Articles_Blog_Details_Updated_at')}{' '}
                        {milisecondsToDDMMYYYY(article.updated_at)}
                      </div>
                    ) : (
                      <div>
                        {LPTranslate('Articles_Blog_Details_Published_at')}{' '}
                        {milisecondsToDDMMYYYY(article.created_at)}
                      </div>
                    )}
                  </div>
                  <h1 className="content-head">{article.title}</h1>
                </div>
                <h2 className="support-text">{article.description}</h2>
              </div>

              <div className="tags-container">
                {articleAreas?.length > 0 &&
                  articleAreas.map((value, key) => {
                    return AreaOfLawTagJSX(value, key);
                  })}
              </div>
            </div>
          </div>

          <div className="container-tag">
            <div className="article-type-tag">
              <span>
                {LPTranslate('AAM_Form_1_Input_Description_Reading_Time_1')}
              </span>
            </div>
            <span className="article-read-time">
              {readingTime(article.content)}
              {LPTranslate('AAM_Form_1_Input_Description_Reading_Time_2')}
            </span>
          </div>

          <div className="blog-page-header-container-image">
            <div className="blog-page-header-container-image-content">
              <img
                className="lawp-article-blog-page-details-article-image"
                src={articleImage}
                alt="article cover"
              />
            </div>
          </div>
        </div>

        <div className="content-container">
          <div className="content-text">
            {ReactQuill ? (
              <ReactQuill
                value={convertParagraphToHeader4(article.content)}
                readOnly={true}
                theme={'bubble'}
              />
            ) : (
              <p>Loading editor...</p>
            )}
          </div>

          <ArticleGrayFooter
            lawyer_title={article.lawyer_title}
            lawyer_first_name={article.lawyer_first_name}
            lawyer_last_name={article.lawyer_last_name}
            author_name={article.author_name}
            lawyer_id={article.lawyer_id}
            role={article.role}
            titleText={article.author_name}
          />

          <ArticleLawyerCard
            profImg={profileImage !== null ? profileImage : DefaultAvatar}
            role={article.role}
            lawyer_title={article.lawyer_title}
            lawyer_first_name={article.lawyer_first_name}
            lawyer_last_name={article.lawyer_last_name}
            username={article.author_name}
            link={article.link}
            lawyer_id={article.lawyer_id}
            lawfirm={article.lawyer_lawfirm}
            article_id={article.id}
            article_title={article.title}
            position="top"
          />
        </div>
      </div>
    )
  );
};
export default ArticleBlogPageDetails;
