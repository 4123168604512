import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import German from 'Assets/languageFlags/de.svg';

import 'Assets/styles/NavBarLanguageSelect.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '0px 30px 0px 10px',
    width: 22,
    '& .MuiSelect-select': {
      backgroundColor: 'transparent !important',
      textDecoration: 'none !important',
    },
    '& .MuiSelect-selectMenu': {
      overflow: 'visible !important',
    },
  },
  select: {
    textAlign: 'center',
    textDecoration: 'none',
  },
}));

const NavBarLanguageSelect = () => {
  const classes = useStyles();
  const [countries, setCountries] = React.useState([]);
  // const [country, setCountry] = React.useState(
  //   localStorage.getItem('userLanguage') || 'de'
  // );
  const [country, setCountry] = React.useState('de');
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    let countriesArray = [];
    // countriesArray.push({
    //   label: 'English',
    //   src: English,
    //   iso: 'EN',
    //   link: ' ',
    //   value: 'en',
    // });

    countriesArray.push({
      label: 'Deutsch',
      iso: 'DE',
      src: German,
      link: ' ',
      value: 'de',
    });

    if (process.env.REACT_APP_ENV_NAME === 'staging') {
      countriesArray.push({
        label: 'Română',
        iso: 'RO',
        src: ' ',
        link: ' ',
        value: 'ro',
      });
    }

    setCountries(countriesArray);
  }, []);

  const handleChange = (event) => {
    localStorage.setItem('userLanguage', event.target.value);

    setCountry(event.target.value);

    window.location.reload(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        data-cy={'language-dropdown'}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={country}
        onChange={handleChange}
        name="country"
        variant="standard"
        disableUnderline
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        IconComponent={() => null}
      >
        {countries.map((option, key) => (
          <MenuItem value={option.value} key={key} data-cy={'language-button'}>
            <h3 style={{ margin: 0, fontSize: 16 }}>{option.iso}</h3>
            {/* <img
              src={option.src}
              alt={option.label}
              style={{
                width: '20px',
                height: '20px',
              }}
            /> */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default NavBarLanguageSelect;
