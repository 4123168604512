import translations from './languages/translations_v2';
import { isBrowser } from 'Services/Utils/ssr';

const setUserLanguageFromLocalStorage = () => {
  if (!isBrowser()) return 'de';

  let storedLanguage = localStorage.getItem('userLanguage');
  if (storedLanguage) {
    return storedLanguage;
  }

  const timeZoneCityToCountry = {
    Berlin: 'Germany',
    Busingen: 'Germany',
  };

  const browserLanguage = navigator.language || navigator.userLanguage;
  if (browserLanguage.startsWith('de')) {
    localStorage.setItem('userLanguage', 'de');
    return 'de';
  }

  if (Intl) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userCity = userTimeZone.split('/').pop();
    const userCountry = timeZoneCityToCountry[userCity];

    if (userCountry === 'Germany') {
      localStorage.setItem('userLanguage', 'de');
      return 'de';
    }
  }

  localStorage.setItem('userLanguage', 'en');
  return 'en';
};

const LPTranslate = (key) => {
  const language = setUserLanguageFromLocalStorage();

  try {
    if (language !== null) return translations[key][language];

    return translations[key]['de'];
  } catch (e) {
    console.log(e);
  }
};

const LPTranslateWithLanguage = (key, language) => {
  try {
    if (language !== null) return translations[key][language];

    return translations[key]['de'];
  } catch (e) {
    console.log(e);
  }
};

export { LPTranslate, LPTranslateWithLanguage };
