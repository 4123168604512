import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import chevronLeft from 'Assets/pictures/chevron-left.svg';
import chevronRight from 'Assets/pictures/chevron-right.svg';
import arrowRight from 'Assets/pictures/arrow-right.svg';

import {
  transformHolidays,
  transformReservedAppointments,
  transformSchedule,
} from 'Services/Utils/calendar/calendar';

import 'Assets/styles/calendar/newMeetingCalendar.scss';

const translateDays = {
  Monday: LPTranslate('Common2_Monday'),
  Tuesday: LPTranslate('Common2_Tuesday'),
  Wednesday: LPTranslate('Common2_Wednesday'),
  Thursday: LPTranslate('Common2_Thursday'),
  Friday: LPTranslate('Common2_Friday'),
  Saturday: LPTranslate('Common2_Saturday'),
  Sunday: LPTranslate('Common2_Sunday'),
};

// object of lawyer reserved appointments
// appointmentsReserved = {date: ["hour", "hour", ...], date: ["hour", ...]}
// type = {string:[string, string, ...], ...}

// object of lawyer holidays
// lawyerHolidays = {date("${day} ${full name month} ${full year}"):boolean, date:boolean, ...}
// type = {string:boolean, ...}

// object of lawyer schedule by week index(1-> Monday, 2-> Tuesday and so on)
// lawyerSchedule = {WeekDayIndex:[hour, hour, ...], WeekDayIndex:[hour, hour, ...], ...}
// type = {Number:[string, string, ...], ...}

const NewMeetingCalendar = (props) => {
  const { backendData, setNextStepDate, setNextStepHour } = props;

  const [currentDate] = useState(moment());

  const [date, setDate] = useState(moment());
  const [dateSelected, setDateSelected] = useState('');

  const [lawyerHolidays] = useState(transformHolidays(backendData.holidays));

  const [appointmentsReserved] = useState(
    transformReservedAppointments(backendData.reserved)
  );

  const [lawyerSchedule] = useState(transformSchedule(backendData.schedule));

  // first calendar render and event listener for available appointments
  useEffect(() => {
    renderCalendar();
    window.addEventListener('click', showAppointments);
    window.addEventListener('click', clickAppointment);
    window.addEventListener('click', nextStep);
    return () => {
      window.removeEventListener('click', showAppointments);
      window.removeEventListener('click', clickAppointment);
      window.removeEventListener('click', nextStep);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCalendar = () => {
    const lastDayCurrentMonth = Number(
      moment(date).subtract(0, 'months').endOf('month').format('DD')
    );

    const lastDayPreviousMonth = Number(
      moment(date).subtract(1, 'months').endOf('month').format('DD')
    );

    const firstCurrentMonthDayIndex = moment(date)
      .startOf('month')
      .isoWeekday();

    const monthDays = document.querySelector('.meeting-calendar-days');
    let days = '';
    // daysIndex represents how many days i must render from next month, i will always have 0-6 previous days, never more, but i can have up to 12 next days

    let daysIndex = 0;
    for (let x = firstCurrentMonthDayIndex - 1; x > 0; x--) {
      days += `<div class="meeting-calendar-prev-date">${
        lastDayPreviousMonth - x + 1
      }</div>`;
      daysIndex++;
    }

    // for every cell i verify if it has any appointments left and is a work day for the lawyer
    let renderAvailableCell = false;
    for (let i = 1; i <= lastDayCurrentMonth; i++) {
      // cellDate = the date for current month possible cell calendar
      const cellDate = moment(date).set('date', i);
      // index of current week that will be rendered [1(Monday)...7(Sunday)]
      const currentWeekDayIndex = moment(cellDate).format('E');

      // the current weekday index must be in lawyerSchedule
      // the lawyer must have appointments left in the current day
      renderAvailableCell = false;
      if (
        currentWeekDayIndex in lawyerSchedule &&
        lawyerSchedule[currentWeekDayIndex] &&
        lawyerSchedule[currentWeekDayIndex].length > 0
      ) {
        // isBefore = condition that current cellDate is before the current date of today
        const isBefore = cellDate < currentDate;

        // the cell must be after the current date of today
        // we check if there is an holiday into the current day
        // we render if there is no current holiday and if the cellDate is after/equal todays date
        // checkFreeAppointments function that returns if there are at least one appointment for that day
        if (!isBefore) {
          if (lawyerHolidays.length > 0) {
            if (!lawyerHolidays.includes(cellDate.format('DD MMMM YYYY'))) {
              renderAvailableCell = checkFreeAppointments(
                cellDate,
                currentWeekDayIndex
              );
            }
          } else {
            renderAvailableCell = checkFreeAppointments(
              cellDate,
              currentWeekDayIndex
            );
          }
        }
      }

      if (renderAvailableCell) {
        // const cellDate = moment(date, 'DD MM YYY').set('date', i);
        const string1 = moment(currentDate).format('DD MM YYYY');
        const string2 = moment(date).set('date', i).format('DD MM YYYY');
        var x = moment(string1, 'DD MM YYYY');

        if (x.isSame(moment(string2, 'DD MM YYYY'))) {
          days += `<div class="meeting-calendar-available" data-day-button data-current-day>${i}</div>`;
        } else {
          days += `<div class="meeting-calendar-available" data-day-button data-cy="meeting-date-button">${i}</div>`;
        }
      } else {
        days += `<div>${i}</div>`;
      }

      daysIndex++;
    }

    // next month dates
    // there are maximum 42 days rendered on calendar, now we must render the rest

    const nextDays = 42 - daysIndex;
    for (let j = 1; j <= nextDays; j++) {
      days += `<div class="meeting-calendar-next-date">${j}</div>`;
    }
    // add the children to component
    if (monthDays) {
      monthDays.innerHTML = days;
    }
  };

  // verify if cell have appointments left
  const checkFreeAppointments = (cellDate, currentWeekDayIndex) => {
    const fullDay = moment(cellDate).format('DD MMMM YYYY');
    const today = moment(currentDate);
    let freeAppointments = false;

    if (lawyerSchedule[currentWeekDayIndex]) {
      for (let i = 0; i < lawyerSchedule[currentWeekDayIndex].length; i++) {
        // if there are at least one possible future appointment free in his schedule for that day
        // we compare the date of today with the date of cell and appointment, to see if there is possible to render it
        if (
          !appointmentsReserved[fullDay] ||
          !appointmentsReserved[fullDay].includes(
            lawyerSchedule[currentWeekDayIndex][i]
          )
        ) {
          const hourMinutes = lawyerSchedule[currentWeekDayIndex][i].split(':');
          const cellHour = moment(cellDate).set({
            hour: hourMinutes[0],
            minute: hourMinutes[1],
            seconds: '00',
          });

          if (cellHour > today) {
            // there is at least one possible future appointment
            freeAppointments = true;
          }
        }
      }
    }

    return freeAppointments;
  };

  // change month depending on the button pressed next or prev month and set new date
  // change the innerhtml for month and year top
  const changeMonth = (type) => {
    if (type === 'prev') {
      const newDate = date.subtract(1, 'month');
      setDate(newDate);
    } else if (type === 'next') {
      const newDate = date.add(1, 'month');
      setDate(newDate);
    }
    document.querySelector(
      '.meeting-calendar-month-year'
    ).innerHTML = `${date.format('MMMM')}, ${date.format('YYYY')}`;

    const appointmentsContainer = document.querySelector(
      '.meeting-calendar-appointments'
    );
    if (appointmentsContainer.classList.contains('active'))
      appointmentsContainer.classList.remove('active');

    const appTest = document.querySelector('.meeting-calendar-date-picker');

    appTest.classList.add('active123123');

    renderCalendar();
  };

  // event listener for available appointments
  const showAppointments = (event) => {
    let eventTarget = null;

    if (event.target.classList.contains('meeting-calendar-available')) {
      // normal cell button is pressed
      eventTarget = event.target;
    } else if (
      event.target.classList.contains('meeting-calendar-action-button')
    ) {
      // set calendar date and try to open the appointments
      handleTodayButton();
      eventTarget = document.querySelector('[data-current-day]');
    } else {
      return;
    }
    if (!eventTarget) {
      // if something happens, we just render the current month, year calendar
      handleTodayButton();
      return;
    }
    // we dont toggle if there is active
    const appointmentsContainer = document.querySelector(
      '.meeting-calendar-appointments'
    );
    if (!appointmentsContainer.classList.contains('active')) {
      appointmentsContainer.classList.toggle('active');
    }

    const meetingCalendarDatePicker = document.querySelector(
      '.meeting-calendar-date-picker'
    );

    if (!meetingCalendarDatePicker.classList.contains('active')) {
      meetingCalendarDatePicker.classList.toggle('active');
    }

    // we remove the inactive class from cells
    if (eventTarget.classList.contains('meeting-calendar-available-inactive')) {
      eventTarget.classList.remove('meeting-calendar-available-inactive');
    }

    // toggle active class on cell pressed, if it's the same pressed, we do nothing
    if (!eventTarget.classList.contains('active')) {
      eventTarget.classList.toggle('active');
    }
    // for every inactive cell
    document.querySelectorAll('[data-day-button]').forEach((inactiveButton) => {
      // we add inactive class only the the ones beside the one clicked, or the ones that already had the inactive class
      if (!inactiveButton.classList.contains('active')) {
        if (
          !inactiveButton.classList.contains(
            'meeting-calendar-available-inactive'
          )
        ) {
          inactiveButton.classList.add('meeting-calendar-available-inactive');
        }
      }
    });
    document
      .querySelectorAll('[data-day-button].active')
      .forEach((dayButton) => {
        if (eventTarget === dayButton) return;
        // if other cell was pressed, we make the old one inactive
        dayButton.classList.remove('active');
        dayButton.classList.add('meeting-calendar-available-inactive');
      });
    // day we pressed(cell pressed)
    const dayPressed =
      eventTarget.innerHTML.length < 2
        ? `0${eventTarget.innerHTML}`
        : eventTarget.innerHTML;

    // to show the date in appointments modal
    const dt = `${moment(date).format('YYYY-MM')}-${dayPressed}`;
    const dayName = moment(dt).format('dddd');
    const currentWeekDayIndex = moment(dt).format('E');
    // "Day name", "DD.MM.YYYY"
    setDateSelected(
      `${translateDays[dayName]}, ${dayPressed}.${moment(date).format(
        'MM.YYYY'
      )}`
    );
    // cell date and todays date, we need them to render the future appointments
    const cellDate = moment(date).set({
      date: dayPressed,
    });
    const today = moment(currentDate);
    // if there are appointments in lawyer schedule
    if (
      currentWeekDayIndex in lawyerSchedule &&
      lawyerSchedule[currentWeekDayIndex].length > 0
    ) {
      // "DD MMMM YYYY"
      const fullDay = `${dayPressed} ${moment(date).format('MMMM YYYY')}`;
      // list of free appointments
      const freeAppointments = [];
      for (let i = 0; i < lawyerSchedule[currentWeekDayIndex].length; i++) {
        // if there are at least one possible future appointment free in his schedule for that day
        // we compare the date of today with the date of cell and appointment, to see if there is possible to render it
        if (
          !appointmentsReserved[fullDay] ||
          !appointmentsReserved[fullDay].includes(
            lawyerSchedule[currentWeekDayIndex][i]
          )
        ) {
          // x2
          const hourMinutes = lawyerSchedule[currentWeekDayIndex][i].split(':');
          // half hour delay
          const cellHour = moment(cellDate).set({
            hour: hourMinutes[0],
            minute: hourMinutes[1],
            seconds: '00',
          });

          if (cellHour > today) {
            // all the possible future appointments
            freeAppointments.push(lawyerSchedule[currentWeekDayIndex][i]);
          }
        }
      }

      const appointmentButton = document.querySelector(
        '.meeting-calendar-appointments-button-container'
      );
      // render the appointments buttons
      let buttons = '';
      for (let x = 0; x < freeAppointments.length; x++) {
        buttons += `<div class="meeting-calendar-appointment-button" data-cy="meeting-hour-button" data-hour-button data-hour="${freeAppointments[x]}">${freeAppointments[x]} Uhr</div>`;
      }
      appointmentButton.innerHTML = buttons;
    }
  };

  const clickAppointment = (event) => {
    // if only an appointment was clicked
    if (event.target.className !== 'meeting-calendar-appointment-button') {
      return;
    }
    // toggle to active class button and render new html and css
    event.target.classList.toggle('active');
    event.target.innerHTML = `<div class="meeting-calendar-appointment-button-selected" >${event.target.dataset.hour} Uhr</div><div class="meeting-calendar-next-step" data-cy="next-step-button" data-nextstep><img data-nextstep src=${arrowRight} alt="arrowRight"/></div>`;

    // for every appointment clicked
    document
      .querySelectorAll('[data-hour-button].active')
      .forEach((hourButton) => {
        // if it's the same button we do nothing
        if (event.target === hourButton) {
          return;
        }
        // if not we remove the old active button and render the normal button
        hourButton.classList.remove('active');
        hourButton.innerHTML = `${event.target.dataset.hour} Uhr`;
      });
  };

  const nextStep = (event) => {
    if (event.target.dataset.nextstep === undefined) {
      return;
    }
    // if next step button is pressed
    // getting the appointment date and hour:minute

    const date = document.querySelector(
      '.meeting-calendar-appointments-day-selected'
    ).innerHTML;
    const hour = document.querySelector(
      '.meeting-calendar-appointment-button-selected'
    ).innerHTML;
    // set the date and hour appointment to use in next modal
    setNextStepDate(date);
    setNextStepHour(hour.split(' ')[0]);
  };

  const handleTodayButton = () => {
    // change the calendar date and render the cell dates

    date.set(currentDate.toObject());
    document.querySelector(
      '.meeting-calendar-month-year'
    ).innerHTML = `${date.format('MMMM')}, ${date.format('YYYY')}`;

    const appointmentsContainer = document.querySelector(
      '.meeting-calendar-appointments'
    );
    if (appointmentsContainer.classList.contains('active'))
      appointmentsContainer.classList.remove('active');
    renderCalendar();
  };

  return (
    <div className="meeting-calendar-date-picker-menu">
      <div className="meeting-calendar-date-picker">
        <div className="meeting-calendar-container">
          <div className="meeting-calendar">
            {/* month */}
            <div className="meeting-calendar-month">
              <div
                data-cy="left-arrow-button"
                className="meeting-calendar-arrow-left button-effect-a"
                onClick={() => changeMonth('prev')}
              >
                <img src={chevronLeft} alt="chevronLeft" />
              </div>
              <div className="meeting-calendar-month-year">
                {`${currentDate.format('MMMM')}, ${date.format('YYYY')}`}
              </div>
              <div
                data-cy="right-arrow-button"
                className="meeting-calendar-arrow-right button-effect-a"
                onClick={() => changeMonth('next')}
              >
                <img src={chevronRight} alt="chevronRight" />
              </div>
            </div>
            {/* actions */}
            <div className="meeting-calendar-actions">
              <div className="meeting-calendar-input-field">{`${currentDate.format(
                'MMM'
              )} ${currentDate.format('DD')}, ${currentDate.format(
                'YYYY'
              )}`}</div>
              {/* afisam onclick pe luna de azi, sau afisam programarile in caz ca exista programari, asemanator cu today cell */}
              <div
                className="meeting-calendar-action-button"
                id={`${moment(currentDate).format('DD MM YYYY')}`}
              >
                {LPTranslate('Common2_Today')}
              </div>
            </div>
            <div className="meeting-calendar-dates">
              {/* weekdays */}
              <div className="meeting-calendar-weekdays">
                <div className="meeting-calendar-weekday">
                  {translateDays.Monday.slice(0, 2)}
                </div>
                <div className="meeting-calendar-weekday">
                  {translateDays.Tuesday.slice(0, 2)}
                </div>
                <div className="meeting-calendar-weekday">
                  {translateDays.Wednesday.slice(0, 2)}
                </div>
                <div className="meeting-calendar-weekday">
                  {translateDays.Thursday.slice(0, 2)}
                </div>
                <div className="meeting-calendar-weekday">
                  {translateDays.Friday.slice(0, 2)}
                </div>
                <div className="meeting-calendar-weekday">
                  {translateDays.Saturday.slice(0, 2)}
                </div>
                <div className="meeting-calendar-weekday">
                  {translateDays.Sunday.slice(0, 2)}
                </div>
              </div>
              {/* month days */}
              <div className="meeting-calendar-days"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="appointments-overflow"> */}
      <div className="meeting-calendar-appointments">
        <div className="meeting-calendar-appointments-day-selected">
          {dateSelected}
        </div>
        <div className="meeting-calendar-appointments-button-container"></div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default NewMeetingCalendar;
