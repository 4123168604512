import React, { useState, useEffect } from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import jwt from 'jwt-decode';

import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

import { addAll } from 'Services/Redux/reducers/clientInfo';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import {
  LOGIN_USER,
  SEND_EMAIL_CONFIRMATION_AFTER_REGISTER,
} from 'Services/Queries/user';

import { resetRegister } from 'Services/Redux/reducers/RegisterReducer';

import styles from 'Assets/styles/registerLawyer/CreateLawyerAccount.module.scss';
import check from 'Assets/pictures/checkCircle.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateLawyerAccount = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const registerRedux = useSelector((state) => state.registerStepper.value);

  const [loginUser] = useMutation(LOGIN_USER);
  const [sendEmailConfirmation] = useMutation(
    SEND_EMAIL_CONFIRMATION_AFTER_REGISTER
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleLogin = async () => {
    try {
      // !!! TODO: for lawyer, props.email is an empty string
      const email = props.email ? props.email : registerRedux.userEmail;
      let loginResult = await loginUser({
        variables: {
          input: {
            email: email,
            password: props.passwordOnRegister,
          },
        },
      });
      let { jwtToken, refresh_token } = loginResult.data.loginUser;

      if (loginResult.data.loginUser.status === 'error') {
        setSnackbarMessage(LPTranslate('Error_Server_Down'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        dispatch(resetRegister());
        const token_decoded = await jwt(jwtToken);

        let tokenInfo = {
          token: jwtToken,
          refresh_token: refresh_token,
          role: token_decoded.role,
          email: token_decoded.email,
          name: token_decoded.username,
          first_name: token_decoded.first_name,
          title: token_decoded.title,
          last_name: token_decoded.last_name,
          user_id: parseInt(token_decoded.user_id),
          role_id: parseInt(token_decoded.role_id),
        };

        localStorage.setItem('anwadoTokenInfo', JSON.stringify(tokenInfo));
        localStorage.setItem('remember', true);

        dispatch(addAll(tokenInfo));

        if (token_decoded.role === 'lawyer') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'lawyer-register-finished-custom-event',
          });
          navigate('/updateLawyerPage');
        } else if (token_decoded.role === 'client') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'client-register-finished-custom-event',
          });
          navigate('/updateClientProfile');
        } else {
          navigate('/');
        }
      }
    } catch (error) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const sendMail = async () => {
      // console.log(props);
      // console.log(registerRedux);
      try {
        const email = props.email ? props.email : registerRedux.userEmail;
        let name = props.name;

        if (!name || name.includes('undefined') || name === ' ') {
          name = `${registerRedux.firstName}  ${registerRedux.lastName}`;
        }

        await sendEmailConfirmation({
          variables: {
            name: name,
            email: email,
          },
        });
      } catch (err) { }
    };

    sendMail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.emailCheckerSection}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={
              snackbarSeverity === 'error' && {
                backgroundColor: '#7f56d9 !important',
              }
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <div className={styles.emailCheckerContainer}>
          <div className={styles.frame2}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.featuredIcon}>
                  <div className={styles.checkCircle}>
                    <img src={check} alt="key" />
                  </div>
                </div>

                <div className={styles.textAndSupportingText}>
                  <p className={styles.text}>
                    {LPTranslate('Common_Account_Created_Title')}
                  </p>

                  <p className={styles.supportingText}>
                    {LPTranslate('RC_Page_6_Description')}
                  </p>
                </div>
              </div>

              <div className={styles.setPasswordButtonContainer}>
                <div className={styles.setPasswordCodeButton}>
                  <button
                    data-cy="submit-button"
                    className={styles.setPasswordButtonBase}
                    onClick={handleLogin}
                  >
                    <p className={styles.setPasswordButtonText}>
                      {LPTranslate('RC_Page_4_Next_Button')}
                    </p>
                  </button>
                </div>
              </div>

              <div className={styles.loginButtonContainer}>
                <button
                  data-cy="homepage-button"
                  className={styles.loginButtonBase}
                  onClick={() => navigate('/')}
                >
                  <ArrowBackIcon sx={{ color: '#667085' }} />
                  <p className={styles.loginButtonText}>
                    {LPTranslate('Button_Back_To_Homepage')}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateLawyerAccount;
