import React from 'react';

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import FaqLawyerComponent from 'Components/CommonComponents/FaqLawyerComponent';
import MetaDecorator from 'Layouts/MetaDecorator';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import mailIcon from 'Assets/pictures/mailIcon.svg';
import lightIcon from 'Assets/pictures/lightIcon.svg';
import checkPurple from 'Assets/pictures/checkPurple.svg';
import policyImage1 from 'Assets/pictures/policyImage1.png';
import policyImage2 from 'Assets/pictures/policyImage2.png';
import policyImage3 from 'Assets/pictures/policyImage3.png';
import heartWhiteIcon from 'Assets/pictures/heartWhiteIcon.svg';
import friendsWhiteIcon from 'Assets/pictures/friendsWhiteIcon.svg';
import graphArrowWhiteIcon from 'Assets/pictures/graphArrowWhiteIcon.svg';
import smileWhiteIcon from 'Assets/pictures/smileWhiteIcon.svg';
import flagWhiteIcon from 'Assets/pictures/flagWhiteIcon.svg';
import lightningWhiteIcon from 'Assets/pictures/lightningWhiteIcon.svg';
import imagesGroup2 from 'Assets/pictures/imagesGroup2.png';
import 'Assets/styles/help/PolicyPage.scss';

const valuesData = [
  {
    picture: friendsWhiteIcon,
    cardTitle: LPTranslate('FUP_Info_2_Box_1_Title'),
    cardText: LPTranslate('FUP_Info_2_Box_1_Description'),
  },
  {
    picture: heartWhiteIcon,
    cardTitle: LPTranslate('FUP_Info_2_Box_2_Title'),
    cardText: LPTranslate('FUP_Info_2_Box_2_Description'),
  },
  {
    picture: graphArrowWhiteIcon,
    cardTitle: LPTranslate('FUP_Info_2_Box_3_Title'),
    cardText: LPTranslate('FUP_Info_2_Box_3_Description'),
  },
  {
    picture: smileWhiteIcon,
    cardTitle: LPTranslate('FUP_Info_2_Box_4_Title'),
    cardText: LPTranslate('FUP_Info_2_Box_4_Description'),
  },
  {
    picture: flagWhiteIcon,
    cardTitle: LPTranslate('FUP_Info_2_Box_5_Title'),
    cardText: LPTranslate('FUP_Info_2_Box_5_Description'),
  },
  {
    picture: lightningWhiteIcon,
    cardTitle: LPTranslate('FUP_Info_2_Box_6_Title'),
    cardText: LPTranslate('FUP_Info_2_Box_6_Description'),
  },
];

let faqsDummyComponentData = [
  {
    cardTitle: LPTranslate('FUP_Info_1_Box_1_Title_1'),
    cardText: LPTranslate('FUP_Info_1_Box_1_Description_1'),
    picture: mailIcon,
  },
  {
    cardTitle: LPTranslate('FUP_Info_1_Box_1_Title_2'),
    cardText: LPTranslate('FUP_Info_1_Box_1_Description_2'),
    picture: lightIcon,
  },
  {
    cardTitle: LPTranslate('FUP_Info_1_Box_1_Title_3'),
    cardText: LPTranslate('FUP_Info_1_Box_1_Description_3'),
    picture: mailIcon,
  },
];

const PolicyPage = () => {
  const navigate = useNavigate();

  return (
    <div className="lawp-policy-page-container">
      <MetaDecorator
        title={LPTranslate('FUP_Meta_Title_1')}
        description={LPTranslate('FUP_Meta_Description_1')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/qualitaetsversprechen`}
      />
      <div className="lawp-policy-page-fair-use-policy">
        <div className="lawp-policy-page-our-values-container">
          <div
            className="lawp-policy-page-our-values-container-info"
            style={{ width: '60%' }}
          >
            <h4>{LPTranslate('FUP_Header_Action_Button_1')}</h4>
            <h2>{LPTranslate('FUP_Header_Title')}</h2>
            <h3>{LPTranslate('FUP_Header_Description')}</h3>
            <div className="buttons-container">
              <Button
                className="contact"
                onClick={() => navigate('/kontakt')}
                data-cy="contact-us"
              >
                <a
                  href={`${process.env.REACT_APP_DNS_URI}/kontakt`}
                  style={{ color: '#344054' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {LPTranslate('FUP_Header_Action_button_2')}
                </a>
              </Button>

              <Button
                className="register"
                data-cy="register"
                onClick={() =>
                  navigate('/registerLawyer', {
                    state: {
                      isSentFromLawyerPage: false,
                    },
                  })
                }
              >
                <a
                  href={`${process.env.REACT_APP_DNS_URI}/registerLawyer`}
                  style={{ color: 'white' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {LPTranslate('FUP_Header_Action_button_3')}
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="lawp-policy-page-special-platform">
        <div className="lawp-policy-page-our-values-container">
          <div className="lawp-policy-page-our-values-container-info">
            <h1>{LPTranslate('FUP_Info_1_Title')}</h1>
            <h3>{LPTranslate('FUP_Info_1_Description')}</h3>
          </div>
        </div>
      </div>

      <div className="lawp-policy-page-info-image-section">
        <div className="left">
          <div className="content">
            <FaqLawyerComponent
              text={faqsDummyComponentData[0].cardText}
              picture={faqsDummyComponentData[0].picture}
              title={faqsDummyComponentData[0].cardTitle}
            />
            <div className="check-group">
              <div className="check-container">
                <div className="check-icon">
                  <img src={checkPurple} alt="check" className="check" />
                </div>
                <h3>{LPTranslate('FUP_Info_CheckList_1_1')}</h3>
              </div>
              <div className="check-container">
                <div className="check-icon">
                  <img src={checkPurple} alt="check" className="check" />
                </div>
                <h3>{LPTranslate('FUP_Info_CheckList_1_2')}</h3>
              </div>
              <div className="check-container">
                <div className="check-icon">
                  <img src={checkPurple} alt="check" className="check" />
                </div>
                <h3>{LPTranslate('FUP_Info_CheckList_1_3')}</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <img src={policyImage1} alt="policyImage1" className="policy-image" />
        </div>
      </div>

      <div className="lawp-policy-page-info-image-section">
        <div className="right">
          <img src={policyImage2} alt="policyImage2" className="policy-image" />
        </div>

        <div className="left">
          <div className="content">
            <FaqLawyerComponent
              text={faqsDummyComponentData[1].cardText}
              picture={faqsDummyComponentData[1].picture}
              title={faqsDummyComponentData[1].cardTitle}
            />
            <div className="check-group">
              <div className="check-container">
                <div className="check-icon">
                  <img src={checkPurple} alt="check" className="check" />
                </div>
                <h3>{LPTranslate('FUP_Info_CheckList_2_1')}</h3>
              </div>
              <div className="check-container">
                <div className="check-icon">
                  <img src={checkPurple} alt="check" className="check" />
                </div>
                <h3>{LPTranslate('FUP_Info_CheckList_2_2')}</h3>
              </div>
              <div className="check-container">
                <div className="check-icon">
                  <img src={checkPurple} alt="check" className="check" />
                </div>
                <h3>{LPTranslate('FUP_Info_CheckList_2_3')}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lawp-policy-page-info-image-section">
        <div className="left">
          <div className="content">
            <FaqLawyerComponent
              text={faqsDummyComponentData[2].cardText}
              picture={faqsDummyComponentData[2].picture}
              title={faqsDummyComponentData[2].cardTitle}
            />
            <div className="check-group">
              <div className="check-container">
                <div className="check-icon">
                  <img src={checkPurple} alt="check" className="check" />
                </div>
                <h3>{LPTranslate('FUP_Info_CheckList_3_1')}</h3>
              </div>
              <div className="check-container">
                <div className="check-icon">
                  <img src={checkPurple} alt="check" className="check" />
                </div>
                <h3>{LPTranslate('FUP_Info_CheckList_3_2')}</h3>
              </div>
              <div className="check-container">
                <div className="check-icon">
                  <img src={checkPurple} alt="check" className="check" />
                </div>
                <h3>{LPTranslate('FUP_Info_CheckList_3_3')}</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <img src={policyImage3} alt="policyImage3" className="policy-image" />
        </div>
      </div>

      <div className="lawp-policy-page-our-values-data-container">
        <div className="title-container">
          <h3 className="title">{LPTranslate('Common2_Our_Values')}</h3>
          <h2 className="subtitle">{LPTranslate('FUP_Info_2_Title')} </h2>
        </div>

        <div className="values-container">
          {valuesData.map((value, index) => (
            <div key={index} className="value">
              <div className="image-container">
                <img src={value.picture} alt="value1" className="image" />
              </div>
              <h2 className="value-title">{value.cardTitle}</h2>
              <h3 className="value-text">{value.cardText}</h3>
            </div>
          ))}
        </div>
      </div>

      <div className="lawp-policy-page-experts-in-your-area-section">
        <div className="left-container">
          <div className="component-card" style={{ alignItems: 'flex-start' }}>
            <h4 className="top-text">
              {LPTranslate('FUP_Info_3_Action_Button_1')}
            </h4>

            <div className="component-text-card" style={{ textAlign: 'left' }}>
              <h2 className="component-card-one-title">
                {LPTranslate('FUP_Info_1_Box_1_Title')}
              </h2>

              <h3 className="component-card-one-text-field">
                {LPTranslate('FUP_Info_2_Description')}
              </h3>
            </div>
          </div>

          <div className="buttons-container">
            <Button
              className="faq-button"
              onClick={() => navigate('/faq')}
              data-cy="go-to-faq"
            >
              <a
                href={`${process.env.REACT_APP_DNS_URI}/faq`}
                style={{ color: '#344054' }}
                onClick={(e) => e.stopPropagation()}
              >
                {LPTranslate('FUP_Info_3_Action_Button_2')}
              </a>
            </Button>

            <Button
              className="lawyer-button"
              onClick={() => navigate('/')}
              data-cy="find-lawyer"
            >
              <a
                href={`${process.env.REACT_APP_DNS_URI}/`}
                style={{ color: 'white' }}
                onClick={(e) => e.stopPropagation()}
              >
                {LPTranslate('FUP_Info_3_Action_Button_3')}
              </a>
            </Button>
          </div>
        </div>

        <div className="right-container">
          <img src={imagesGroup2} alt="placeholder" className="images-group" />
        </div>
      </div>

      <div className="lawp-policy-page-to-ask-section">
        <h2>{LPTranslate('Common2_Any_Questions')}</h2>

        <h3>{LPTranslate('Common2_Join_And_Offer_Services')}</h3>

        <div className="buttons-container">
          <Button
            className="contact-button"
            data-cy="contact-us"
            onClick={() => navigate('/kontakt')}
          >
            <a
              href={`${process.env.REACT_APP_DNS_URI}/kontakt`}
              style={{ color: '#344054' }}
              onClick={(e) => e.stopPropagation()}
            >
              {LPTranslate('Common_Contact_Us')}
            </a>
          </Button>

          <Button
            className="sign-button"
            data-cy="register"
            onClick={() =>
              navigate('/registerLawyer', {
                state: {
                  isSentFromLawyerPage: false,
                },
              })
            }
          >
            <a
              href={`${process.env.REACT_APP_DNS_URI}/registerLawyer`}
              style={{ color: 'white' }}
              onClick={(e) => e.stopPropagation()}
            >
              {LPTranslate('Button_Register_Yourself')}
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PolicyPage;
