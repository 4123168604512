import moment from 'moment';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

const months = [
  LPTranslate('Common2_January'),
  LPTranslate('Common2_February'),
  LPTranslate('Common2_March'),
  LPTranslate('Common2_April'),
  LPTranslate('Common2_May'),
  LPTranslate('Common2_June'),
  LPTranslate('Common2_July'),
  LPTranslate('Common2_August'),
  LPTranslate('Common2_September'),
  LPTranslate('Common2_October'),
  LPTranslate('Common2_November'),
  LPTranslate('Common2_December'),
];

const milisecondsToDDMMYYYY = (miliseconds) => {
  return (
    moment(Number(miliseconds)).format('DD') +
    ' ' +
    months[moment(Number(miliseconds)).format('M') - 1] +
    ' ' +
    moment(Number(miliseconds)).format('YYYY')
  );
};

const milisecondsToMMMDDYYYY = (miliseconds) => {
  return (
    months[moment(Number(miliseconds)).format('M') - 1] +
    ' ' +
    moment(Number(miliseconds)).format('DD') +
    ', ' +
    moment(Number(miliseconds)).format('YYYY')
  );
};

const millisecondsToTimeAgo = (milliseconds) => {
  return moment(Number(milliseconds)).fromNow();
};

export { milisecondsToDDMMYYYY, milisecondsToMMMDDYYYY, millisecondsToTimeAgo };
