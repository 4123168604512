import React from 'react';

import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import {
  UPDATE_SUBSCRIPTION_V2,
  CANCEL_SCHEDULE_SUBSCRIPTION,
  CREATE_SILVER_SCHEDULE,
} from 'Services/Queries/stripe';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import useAlert from 'Services/Utils/hooks/useAlert';
import {
  subscriptionContentText,
  subscriptionContentSupport,
} from 'Services/subscriptionTexts';

import styles from 'Assets/styles/subscription/SubscriptionUpdateBox.module.scss';

const SubscriptionUpdateBox = (props) => {
  const { setAlert } = useAlert();

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const subscription_period_german = {
    title_month: LPTranslate('LSUS_Update_Title_Monthly'),
    title_year: LPTranslate('LSUS_Update_Title_Yearly'),
    description_month: LPTranslate('LSUS_Update_Description_Monthly'),
    description_year: LPTranslate('LSUS_Update_Description_Yearly'),
    end_of_current_month: LPTranslate(
      'LSUS_Update_End_Of_Current_Subscription_Monthly'
    ),
    end_of_current_year: LPTranslate(
      'LSUS_Update_End_Of_Current_Subscription_Yearly'
    ),
    end_of_current_subscription: LPTranslate(
      'LSUS_Update_End_Of_Current_Subscription'
    ),
  };

  const [createSilverSchedule] = useMutation(CREATE_SILVER_SCHEDULE);
  const [updateSubscriptionV2] = useMutation(UPDATE_SUBSCRIPTION_V2);
  const [cancelScheduleSubscription] = useMutation(
    CANCEL_SCHEDULE_SUBSCRIPTION
  );

  const handleUpdate = async () => {
    const result = await updateSubscriptionV2({
      variables: {
        input: {
          lawyer_id: clientInfoRedux.role_id,
          price_id: props.price_id,
          downgrade: false,
        },
      },
    });

    const data = result.data.updateSubscriptionV2;

    if (data.status === 'OK') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'lawyer-update-subscription-custom-event',
      });
      setAlert(LPTranslate('LSUS_Update_Notification_Message'));

      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }

    if (data.status === 'error') {
      setAlert(data.message);
    } else if (data.status === 'redirect' && data.redirect_url.length > 0) {
      window.location.href = data.redirect_url;
    }
  };

  const handleSchedule = async () => {
    try {
      const UpdateSubscriptionRes = await updateSubscriptionV2({
        variables: {
          input: {
            lawyer_id: clientInfoRedux.role_id,
            price_id: props.price_id,
            downgrade: true,
          },
        },
      });

      if (UpdateSubscriptionRes.data.updateSubscriptionV2.status === 'OK') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'lawyer-downgrade-subscription-custom-event',
        });
        setAlert(LPTranslate('LSUS_Downgrade_Notification_Message'));

        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelSchedule = async () => {
    const CancelScheduleSubscription = await cancelScheduleSubscription({
      variables: {
        input: {
          lawyer_id: clientInfoRedux.role_id,
        },
      },
    });
    if (
      CancelScheduleSubscription.data.cancelScheduleSubscription.status ===
      '200'
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'lawyer-cancel-schedule-subscription-custom-event',
      });
      setAlert(LPTranslate('LSUS_Cancellation_Notification_Message'));

      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }
  };
  const handleSilverSchedule = async () => {
    const CreateSilverSchedule = await createSilverSchedule({
      variables: {
        input: {
          lawyer_id: clientInfoRedux.role_id,
        },
      },
    });
    if (CreateSilverSchedule.data.createSilverSchedule.status === '200') {
      setAlert(LPTranslate('LSUS_Downgrade_Notification_Message'));

      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }
  };

  const handleSubscription = () => {
    if (props.type === 'downgrade' && props.newSubscription === 'Silver')
      handleSilverSchedule();
    else if (props.type === 'downgrade') handleSchedule();
    else if (props.type === 'CancelSchedule') handleCancelSchedule();
    else if (props.type === 'update') handleUpdate();
  };

  return (
    <div className={styles['lawp-subscription-update-box-container']}>
      <div className={styles['lawp-subscription-update-box-content']}>
        {/* Text container */}
        <div
          className={
            styles[
            'lawp-subscription-update-box-content-text-and-support-container'
            ]
          }
        >
          <div
            className={
              styles['lawp-subscription-update-box-content-text-container']
            }
          >
            {subscriptionContentText(props, subscription_period_german)}
          </div>
          <div
            className={
              styles['lawp-subscription-update-box-content-support-container']
            }
          >
            {LPTranslate('LSUS_Update_Description')}
          </div>
        </div>
        {/* Alert container */}
        <div
          className={
            styles['lawp-subscription-update-box-content-alert-container']
          }
        >
          <div
            className={
              styles['lawp-subscription-update-box-content-alert-content']
            }
          >
            <div
              className={
                styles[
                'lawp-subscription-update-box-content-alert-content-badge-group'
                ]
              }
            >
              <div
                className={
                  styles[
                  'lawp-subscription-update-box-content-alert-content-badge-container'
                  ]
                }
              >
                <div
                  className={
                    styles[
                    'lawp-subscription-update-box-content-alert-content-badge'
                    ]
                  }
                >
                  {/* {LPTranslate('LSUS_Update_Badge')} */}
                  {LPTranslate('LSUS_Update_Title_Monthly')}
                </div>
              </div>
              <div
                className={
                  styles[
                  'lawp-subscription-update-box-content-alert-content-badge-message'
                  ]
                }
              >
                {props.type === 'downgrade'
                  ? `€${props.newPrice}`
                  : [
                    props.new_recurring_interval === 'year' &&
                      props.type !== 'CancelSchedule'
                      ? `€${props.subscriptionPrice / 12}`
                      : [
                        props.type !== 'CancelSchedule'
                          ? `€${props.subscriptionPrice}`
                          : '€0.00',
                      ],
                  ]}
              </div>
            </div>
            <div
              className={
                styles[
                'lawp-subscription-update-box-content-alert-content-support'
                ]
              }
            >
              {/* in iful asta trebuie modificat textul din upgrade/downgrade subscription */}
              {subscriptionContentSupport(props, subscription_period_german)}
            </div>
          </div>
        </div>
        {/* Actions container */}
        <div
          className={
            styles['lawp-subscription-update-box-content-actions-container']
          }
        >
          <div
            onClick={handleSubscription}
            className={
              styles[
              'lawp-subscription-update-box-content-actions-cancel-button-container'
              ]
            }
          >
            <div
              className={
                styles[
                'lawp-subscription-update-box-content-actions-cancel-button'
                ]
              }
            >
              {props.type === 'CancelSchedule'
                ? LPTranslate('LSUS_Update_Button_Cancel')
                : [
                  props.type === 'downgrade'
                    ? LPTranslate('LSUS_Downgrade_Button')
                    : LPTranslate('LSUS_Update_Button_Accept'),
                ]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionUpdateBox;
