import React from 'react';

import FaqLawyerComponent from 'Components/CommonComponents/FaqLawyerComponent';
import FaqTitleComponent from 'Components/CommonComponents/FaqTitleComponent';
import FaqFooterComponent from 'Components/CommonComponents/FaqFooterComponent';

import firstComponentTexts from 'Services/componentTexts.js';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/common/FaqsComponent.css';

const FaqComponent = (props) => {
  return (
    <div className="FaqsCard">
      <div className="FaqsContent">
        <FaqTitleComponent
          title={'FAQs'}
          subtitle={LPTranslate('LPP_FAQ_Description')}
        />

        <div className="cardComponent">
          <div className="firstComponentTexts">
            {firstComponentTexts?.length > 0 &&
              firstComponentTexts?.map((row, key) => (
                <div key={key}>
                  <FaqLawyerComponent
                    text={row.cardText}
                    picture={row.picture}
                    title={row.cardTitle}
                  />
                </div>
              ))}
          </div>
        </div>

        <FaqFooterComponent
          faqsTitle={LPTranslate('LPP_FAQ_Question')}
          faqText={LPTranslate('LPP_FAQ_Answer')}
          faqTextNotRegistered={LPTranslate('LPP_FAQ_Answer_Not_Registered')}
          lawyer={props.lawyer}
          clientInfoRedux={props.clientInfoRedux}
          reviewsNr={props.reviewsNr}
          averageNr={props.averageNr}
          availability={props.availability}
        />
      </div>
    </div>
  );
};

export default FaqComponent;
