import React, { useCallback, useEffect, useState } from 'react';

import { convertParagraphToHeader4 } from 'Services/Utils/convertParagraphToHeader4';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import desk1 from 'Assets/pictures/desk1.png';
import desk2 from 'Assets/pictures/desk2.png';
import styles from 'Assets/styles/lawyer/LawyerDetailsCard.module.scss';
import 'Assets/quill.css';

const LawyerDetailsCard = (props) => {
  let { lawyer } = props;

  const [lawyerImages, setLawyerImages] = useState([desk1, desk2]);

  let setImages = useCallback(async () => {
    let images = [];

    if (lawyer.lawyerImages.length > 0) {
      for (let i = 0; i < lawyer.lawyerImages.length; i++) {
        let image = lawyer.lawyerImages[i];

        images.push(image);
      }
    }

    setLawyerImages(images);
  }, [lawyer.lawyerImages]);

  useEffect(() => {
    setImages();
  }, [setImages]);

  const [ReactQuill, setReactQuill] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const loadQuill = async () => {
        const Quill = await import('react-quill');
        setReactQuill(() => Quill.default);
        // require('react-quill/dist/quill.snow.css'); // Ensure the CSS is loaded
      };
      loadQuill();
    }
  }, []);

  useEffect(() => {
    if (ReactQuill) {
      // ssr might not correctly apply the font sizes. This is a workaround:
      // go to Assets/quill.css and change the ql-container h5-4-3-2-1 accordingly;

      let SizeStyle = ReactQuill.Quill.import('attributors/style/size');
      SizeStyle.whitelist = ['18px', '22px', '26px', '14px'];
      ReactQuill.Quill.register(SizeStyle, true);
    }
  }, [ReactQuill]);

  return (
    <>
      {lawyer.subscription > 1 && (
        <div className={styles.lawyerDetailsCardOuterContainer}>
          <div className={styles.lawyerDetailsCardInnerContainer}>
            <div className={styles.lawyerDetailsCardBottomContent}>
              {lawyer.self_lawfirm_description && (
                <>
                  <h2 className={styles.lawyerDetailsCardPointsTitle}>
                    {LPTranslate('LPP_Details_Main_Focus')}
                  </h2>

                  <div className={styles.lawyerDetailsCardList}>
                    {ReactQuill ? (
                      <ReactQuill
                        value={convertParagraphToHeader4(
                          lawyer.self_lawfirm_description
                        )}
                        readOnly={true}
                        theme={'bubble'}
                      />
                    ) : (
                      <p>Loading editor...</p>
                    )}
                  </div>
                </>
              )}

              <div className={styles.lawyerDetailsCardImageContent}>
                {lawyerImages.map((image, index) => (
                  <img
                    key={index}
                    className={styles.lawyerDetailsImage}
                    src={image}
                    alt="lawyerImg"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LawyerDetailsCard;
