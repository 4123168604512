import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import { useLazyQuery } from '@apollo/client';

import { resetClientInfo } from 'Services/Redux/reducers/clientInfo';
import { GET_USER_PROFILE_IMAGE } from 'Services/Queries/user';

import {
  // addFirstname,
  // addLastname,
  // addRegisterEmail,
  setClientStepper,
  setLawyerStepper,
} from 'Services/Redux/reducers/RegisterReducer';

import { createUrlSlug } from 'Services/Utils/metaData';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { removeTokenInfo } from 'Services/Utils/token';
import { isBrowser } from 'Services/Utils/ssr';

import logo from 'Assets/pictures/anwado-logo3.png';
import userAvatar from 'Assets/pictures/avatarIcon.svg';
import operatorIcon from 'Assets/pictures/AnwadoLogoPurple.png';

import 'Assets/styles/NavBar.scss';

// for testing purposes
const useOutsideAlerter = (ref) => {
  useEffect(() => {
    if (!isBrowser()) return;

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        document
          .getElementsByClassName('dropdown-content')[0]
          .classList.remove('show');
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);
};

function NavBarComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const wrapperRef = useRef(null); // for testing purposes

  const [profileImage, setProfileImage] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [windowWidth, setWindowWidth] = useState(
    isBrowser() ? window.innerWidth : 0
  );

  const [userImage, { loading, data }] = useLazyQuery(GET_USER_PROFILE_IMAGE, {
    fetchPolicy: 'no-cache',
  });

  const handleImageUpdate = (e) => {
    if (e.type === 'UpdateUserInformationNavbar' && !loading) {
      fetchUserProfileImage();
    }
  };

  if (isBrowser()) {
    document.addEventListener(
      'UpdateUserInformationNavbar',
      handleImageUpdate,
      false
    );
  }

  const handleButton = (link) => {
    if (clientInfoRedux.role === 'client') {
      if (location.pathname === '/' && link === '/') {
        window.location.reload();
      } else if (link === '/updatePage') {
        navigate('/updateClientProfile');
      } else {
        navigate(link);
      }
    } else if (clientInfoRedux.role === 'lawyer') {
      if (location.pathname === '/' && link === '/') {
        window.location.reload();
      } else if (link === '/updatePage') {
        navigate('/updateLawyerPage');
      } else {
        navigate(link);
      }
    } else {
      dispatch(setClientStepper(0));
      dispatch(setLawyerStepper(0));
      navigate(link);
    }
  };

  const handleLogout = () => {
    removeTokenInfo();
    sessionStorage.clear();
    dispatch(resetClientInfo());

    navigate('/');
  };

  const fetchUserProfileImage = useCallback(async () => {
    if (clientInfoRedux.user_id && clientInfoRedux.role !== 'operator') {
      await userImage({
        variables: {
          id: clientInfoRedux.user_id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInfoRedux.user_id, userImage]);

  useEffect(() => {
    fetchUserProfileImage();
  }, [fetchUserProfileImage]);

  useEffect(() => {
    if (data && data.userInformationNavbar) {
      setUsername(data.userInformationNavbar.username);
      setEmail(data.userInformationNavbar.email);
      setProfileImage(data.userInformationNavbar.profileImage);
    }
  }, [data]);

  useEffect(() => {
    if (!isBrowser()) return;

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onMouseOver = () => {
    const container = document.getElementsByClassName('dropdown-content')[0];

    container.style.display = 'flex';
    container.style.position = 'absolute';
    container.style.alignItems = 'center';
    container.style.justifyContent = 'center';
    container.style.flexFlow = 'column wrap';
    container.style.top = '100%';
    container.style.right = '0';
  };

  const onMouseOut = () => {
    const container = document.getElementsByClassName('dropdown-content')[0];

    container.style.display = 'none';
  };

  useOutsideAlerter(wrapperRef);

  return (
    <div className="lawp-navbar-center" style={{ height: 80 }}>
      <div
        className="lawp-navbar-menu"
        id="navbar-menu"
        style={
          location.pathname === '/suche' ||
          location.pathname === '/' ||
          location.pathname === '/registerLawyer' ||
          location.pathname.includes('/fachanwalt/') ||
          location.pathname.includes('/ort/') ||
          location.pathname.includes('/aol/')
            ? {
                position: 'fixed',
              }
            : {}
        }
      >
        {' '}
        <div className="logoContainer">
          <img
            className="logoAnwado"
            src={logo}
            alt="logoAnwado"
            data-cy={'navbar-logo-button'}
            onClick={() => handleButton('/')}
          />
        </div>
        <div className="buttons">
          {clientInfoRedux.token.length === 0 ? (
            <>
              {windowWidth > 1060 && (
                <>
                  {isBrowser() && window.location.pathname !== '/anwaelte' && (
                    <button
                      className="lawyerButton"
                      onClick={() => handleButton('/anwaelte')}
                      data-cy={'navbar-homepage-lawyer-button'}
                    >
                      {LPTranslate('Common_Are_You_A_Lawyer')}
                    </button>
                  )}

                  <button
                    className="loginbutton"
                    onClick={() => handleButton('/login')}
                    data-cy={'navbar-login-button'}
                  >
                    {LPTranslate('Button_Login')}
                  </button>

                  <button
                    className="registerbutton"
                    data-cy={'navbar-register-button'}
                    onClick={() => {
                      if (
                        isBrowser() &&
                        window.location.pathname === '/anwaelte'
                      ) {
                        navigate('/registerLawyer', {
                          state: {
                            isSentFromLawyerPage: false,
                          },
                        });
                      } else handleButton('/registerClient');
                    }}
                  >
                    {LPTranslate('Button_Sign_Up')}
                  </button>
                </>
              )}

              {windowWidth <= 1060 && (
                <>
                  <div
                    className="dropdown"
                    onMouseOver={() => onMouseOver()}
                    onMouseOut={() => onMouseOut()}
                  >
                    <Button data-cy={'filters-button'} disableRipple>
                      <MenuRoundedIcon />
                    </Button>

                    <div className="dropdown-content">
                      <div className="dropdown-menu-base">
                        <button
                          data-cy="settings-button"
                          className="settings"
                          onClick={() => handleButton('/anwaelte')}
                        >
                          {LPTranslate('Common_Are_You_A_Lawyer')}
                        </button>

                        <div className="dropdown-divider" />

                        <button
                          data-cy="logout-button"
                          className="logout"
                          onClick={() => handleButton('/login')}
                        >
                          {LPTranslate('Button_Login')}
                        </button>

                        <div className="dropdown-divider" />

                        <button
                          data-cy="logout-button"
                          className="logout"
                          onClick={() => {
                            if (
                              isBrowser() &&
                              window.location.pathname === '/anwaelte'
                            ) {
                              navigate('/registerLawyer', {
                                state: {
                                  isSentFromLawyerPage: false,
                                },
                              });
                            } else handleButton('/registerClient');
                          }}
                        >
                          {LPTranslate('Button_Sign_Up')}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : clientInfoRedux.role === 'lawyer' ||
            clientInfoRedux.role === 'client' ? (
            <div className="loggedInContainer" ref={wrapperRef}>
              <div
                className="dropdown"
                onMouseOver={() => onMouseOver()}
                onMouseOut={() => onMouseOut()}
              >
                <img
                  data-cy="profile-button"
                  className={
                    clientInfoRedux.role === 'operator'
                      ? operatorIcon
                      : profileImage
                      ? 'avatar'
                      : 'default-header-avatar'
                  }
                  src={profileImage ? profileImage : userAvatar}
                  alt="dropdown"
                />

                <div className="dropdown-content">
                  <div className="dropdown-list-header">
                    <div className="dropdown-avatar-label-group">
                      <div className="dropdown-avatar">
                        <div className="dropdown-avatar-user">
                          <img
                            src={profileImage ? profileImage : userAvatar}
                            alt="avatar"
                            className={
                              profileImage ? 'avatar-image' : 'default-avatar'
                            }
                          />
                        </div>
                        <div className="avatar-online-indicator" />
                      </div>

                      <div className="dropdown-avatar-text-and-supporting-text">
                        <div className="dropdown-avatar-text">{username}</div>
                        <div className="dropdown-avatar-supporting-text">
                          {email}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dropdown-divider" />

                  <div className="dropdown-menu-base">
                    {clientInfoRedux.role === 'lawyer' && (
                      <>
                        <button
                          data-cy="public-profile-button"
                          className="public-profile-button"
                          onClick={() => {
                            let slug = createUrlSlug(
                              '',
                              clientInfoRedux.first_name,
                              clientInfoRedux.last_name,
                              ''
                            );

                            slug += `-${clientInfoRedux.role_id}`;
                            navigate(`/anwaelte/${slug}`);
                          }}
                        >
                          {LPTranslate('Navbar_Public_Profile')}
                        </button>
                        <div className="dropdown-divider" />
                      </>
                    )}

                    <button
                      data-cy="settings-button"
                      className="settings"
                      onClick={() => handleButton('/updatePage')}
                    >
                      {LPTranslate('Navbar_Settings')}
                    </button>

                    <div className="dropdown-divider" />

                    <button
                      data-cy="logout-button"
                      className="logout"
                      onClick={() => handleLogout()}
                    >
                      {LPTranslate('Navbar_Logout')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <button className="logoutbutton" onClick={() => handleLogout()}>
              Logout
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
export default NavBarComponent;
