import React from 'react';

import { useState, useEffect, useMemo } from 'react';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import {
  customLinkSanitizer,
  iconsStyle,
  fontSizeStyle,
  headerFormat,
} from 'Services/Utils/textEditorUtils';
import { isBrowser } from 'Services/Utils/ssr';

import 'Assets/quill.css';
import 'Assets/styles/lawyer/UpdateLawyerQuill.scss';

import DropPicture from 'Assets/pictures/DropDownArrowIcon.svg';

const QuillTextFields = (props) => {
  const [ReactQuill, setReactQuill] = useState(null);
  // this is used so that the editor doesn't get autofocused when it's first rendered, and make the page scroll it it
  const [editorReadOnly, setEditorReadOnly] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const loadQuill = async () => {
        const Quill = await import('react-quill');
        setReactQuill(() => Quill.default);
      };
      loadQuill();
    }
  }, []);

  let { title, id, value, onChange } = props;

  const quillRef = React.useRef();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState('');

  if (ReactQuill) {
    iconsStyle(ReactQuill.Quill.import('ui/icons'));
    ReactQuill.Quill.register(customLinkSanitizer(ReactQuill.Quill), true);
    ReactQuill.Quill.register(fontSizeStyle(ReactQuill.Quill), true);
    ReactQuill.Quill.register(headerFormat(ReactQuill.Quill), true);
  }

  useEffect(() => {
    if (!isBrowser()) return;
    if (!ReactQuill) return;

    const toolbarList = document.querySelectorAll('.toolbar-container');

    toolbarList.forEach((toolbar) => {
      const img = document.createElement('img');
      img.setAttribute('alt', 'downArrow');
      img.setAttribute('src', DropPicture);
      img.setAttribute('class', 'dropPicture');

      const child = toolbar.querySelector('.ql-header.ql-picker');
      const childTwo = child.querySelector('.ql-picker-label');

      const svg = childTwo.lastElementChild;

      childTwo.removeChild(svg);
      childTwo.append(img);
    });

    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.format('header', 3);
      setEditorReadOnly(false);
    }
  }, [ReactQuill]);

  const CustomToolbar = () => (
    <div className="toolbar-container toolbar-test" id={`toolbar-${id}`}>
      <select className="ql-header" defaultValue="4">
        {/* <option selected></option> */}
        <option value="2">{LPTranslate('Common_Huge')}</option>
        <option value="3">{LPTranslate('Common_Large')}</option>
        <option value="4">{LPTranslate('Common_Medium')}</option>
        <option value="5">{LPTranslate('Common_Small')}</option>
      </select>

      <div className=".ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) ">
        <div className=".dropImg"></div>
      </div>
      <button
        data-cy={
          props.quill_buttons_data_cy ? 'aam-ql-bold-button' : 'ql-bold-button'
        }
        className="ql-bold"
      />
      <button
        data-cy={
          props.quill_buttons_data_cy
            ? 'aam-ql-italic-button'
            : 'ql-italic-button'
        }
        className="ql-italic"
      />
      <button
        data-cy={
          props.quill_buttons_data_cy ? 'aam-ql-link-button' : 'ql-link-button'
        }
        className="ql-link"
      />

      <button
        data-cy={
          props.quill_buttons_data_cy
            ? 'aam-ql-bullet-button'
            : 'ql-bullet-button'
        }
        className="ql-list"
        value="bullet"
      ></button>
      <button
        data-cy={
          props.quill_buttons_data_cy ? 'aam-ql-list-button' : 'ql-list-button'
        }
        className="ql-list"
        value="ordered"
      ></button>
    </div>
  );

  const handleChange = (val, delta, source, editor) => {
    if (val === value) return;

    if (val === '<p><br></p>') val = '';

    onChange(val);

    if (previewOpen) {
      setPreviewContent(editor.getText());
    }
  };

  const quillFormats = ['header', 'bold', 'italic', 'list', 'link'];
  const quillModules = useMemo(() => {
    return {
      toolbar: {
        container: `#toolbar-${id}`,
        handlers: {
          preview: function (value) {
            const html = this.quill.root.innerHTML;
            setPreviewContent(html);
            setPreviewOpen(!previewOpen);
          },
        },
      },
    };
  }, [id, previewOpen]);

  return (
    <>
      <div className="quillTab">
        <div className="text-editor">
          {props.titleAbove ? (
            <>
              <div className="titleElementAbove">{title}</div>
              <div className="toolBarBelow">{CustomToolbar(id)}</div>
            </>
          ) : (
            <>
              {CustomToolbar(id)}

              <div className="titleElement">{title}</div>
            </>
          )}

          <div
            className="quill-container"
            data-text-editor={`name${id}`}
            data-cy={props.data_cy}
          >
            {ReactQuill ? (
              <ReactQuill
                ref={quillRef}
                value={value}
                onChange={(e, delta, source, editor) => {
                  handleChange(e, delta, source, editor);
                }}
                bounds={`[data-text-editor="name${id}"]`}
                modules={quillModules}
                formats={quillFormats}
                readOnly={editorReadOnly}
              />
            ) : (
              <div> Loading...</div>
            )}
          </div>
          {previewOpen ? (
            <div dangerouslySetInnerHTML={{ __html: previewContent }} />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default QuillTextFields;
