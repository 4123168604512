import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import fetch from 'cross-fetch';

import TokenMiddleware from './Middleware/TokenMiddleware';
import Router from './Routes/Router';
import LoggedInMiddleware from 'Middleware/LoggedInMiddleware';
import ScrollToTop from 'Components/ScrollToTop';

import CookiesAcceptBar from 'Components/help/CookiesAcceptBar';
import LoadingScreen from 'Components/LoadingScreen';

import 'Assets/styles/App.scss';

import history from './Routes/history';

import { AlertProvider } from 'Components/CommonComponents/SnackbarContext';
import SnackbarComponent from 'Components/CommonComponents/SnackbarComponent';

function App(props) {
  const isServer = props.isServer;

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_URI,
    fetch: fetch,
  });

  const authLink = TokenMiddleware();

  const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  if (isServer) {
    return (
      <>
        <div
          className="lawp-app-container-background"
          style={{
            overflow: 'hidden',
            height: '100vh',
          }}
        >
          <LoadingScreen />
          <div className="lawp-app-container">
            <ScrollToTop />
            <ApolloProvider client={client}>
              <LoggedInMiddleware />

              <AlertProvider>
                <SnackbarComponent />
                <Router sitemaps={props.sitemaps} />
              </AlertProvider>

              <CookiesAcceptBar />
            </ApolloProvider>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="lawp-app-container-background">
          <div className="lawp-app-container">
            <BrowserRouter history={history}>
              <ScrollToTop />
              <ApolloProvider client={client}>
                <LoggedInMiddleware />

                <AlertProvider>
                  <SnackbarComponent />
                  <Router server={false} />
                </AlertProvider>

                <CookiesAcceptBar />
              </ApolloProvider>
            </BrowserRouter>
          </div>
        </div>
      </>
    );
  }
}

export default App;
