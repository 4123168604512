import React, { useState, useEffect, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import leftArrow from 'Assets/pictures/leftArrow.svg';
import rightArrow from 'Assets/pictures/rightArrow.svg';

import { GET_SPECIALITIES } from 'Services/Queries/lawyer';
import { SEARCH_L } from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { fetchUserIP } from 'Services/Utils/apify';
import { isBrowser } from 'Services/Utils/ssr';

import LandingPageTopView from 'Components/LandingPageTopView';
import SearchBarComponent from 'Layouts/searchBar/SearchBarComponent';
import LawyerCard from 'Components/Lawyer/LawyerCard';
import LawyerCardSkeleton from 'Components/Skeletons/LawyerCardSkeleton';
import FiltersDrawer from 'Components/Lawyer/FiltersDrawer';
import Filters from 'Components/Lawyer/Filters';
import MetaDecorator from 'Layouts/MetaDecorator';
// import useAlert from 'Services/Utils/hooks/useAlert';

import styles from 'Assets/styles/user/UserClientPage.module.scss';
import 'Assets/styles/user/HomePage.scss';

const LawyerSpecialityPage = () => {
  //assigned but never used
  // const { setAlert } = useAlert();

  const [getSpecialities, { data: specialitiesData }] =
    useLazyQuery(GET_SPECIALITIES);

  const [areasOfLaw, setAreasOfLaw] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [locationInput, setLocationInput] = useState('');
  const [locationDistanceInput, setLocationDistanceInput] = useState(0);

  const [filterCheckBoxes, setFilterCheckBoxes] = useState({
    sortBy: '',
    hasSpeciality: false,
    OnlineAppointmentBooking: false,
    minRating: 0,
    minReviews: 0,
  });

  const [type, setType] = useState(''); // 'speciality', 'areaOfLaw';
  const [speciality, setSpeciality] = useState('');
  const [fileData, setFileData] = useState({ de: {}, en: {}, ro: {} });

  let language = 'de';
  if (isBrowser()) language = localStorage.getItem('userLanguage') || 'de';

  const [lawyers, setLawyers] = useState([]);
  const [resultsType] = useState('default');
  const [lawyersDB, { loading, error, data }] = useLazyQuery(SEARCH_L);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);

  const navigate = useNavigate();

  ////// Lawyers Fetch:
  const fetchLawyers = useCallback(async () => {
    let searchRelative = checkInput();

    let ip = '0.0.0.0';

    if (searchRelative === 'user') ip = await fetchUserIP();

    await lawyersDB({
      variables: {
        input: {
          search: '',
          cityFilter: [],
          areaOfLawFilter: areasOfLaw,
          specialityFilter: specialities,
          filterCheckBoxes: filterCheckBoxes,
          searchRelativeTo: searchRelative,
          page: page,
          pageLimit: 10,
          userIP: ip.ip,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lawyersDB, areasOfLaw, filterCheckBoxes, page, specialities]);

  useEffect(() => {
    if (data && data.searchL && data.searchL.lawyers) {
      setLawyers(data.searchL.lawyers);
      setPageCount(data.searchL.number_of_pages);
    }
  }, [data]);

  const checkInput = () => {
    let searchRelative;

    if (filterCheckBoxes.sortBy === 'distance') {
      searchRelative = 'user';
    } else if (searchRelative === undefined) {
      searchRelative = '';
    }

    return searchRelative;
  };

  const clearFilters = () => {
    setFilterCheckBoxes({
      sortBy: '',
      hasSpeciality: false,
      OnlineAppointmentBooking: false,
      minRating: 0,
      minReviews: 0,
    });
  };

  const handlePageChange = (value) => {
    if (value >= 1 && value <= pageCount) {
      setPage(value);
    }
  };

  ///// Lawyers Fetch ^

  ////// aol/speciality handler:
  const getAolOfSpecialities = useCallback(async () => {
    if (type === 'speciality') {
      getSpecialities();
    }
  }, [getSpecialities, type]);

  useEffect(() => {
    if (type) getAolOfSpecialities();
  }, [getAolOfSpecialities, type]);

  useEffect(() => {
    if (specialitiesData && speciality) {
      let id = 0;
      for (let i = 0; i < specialitiesData.getSpecialities.length; i++) {
        if (specialitiesData.getSpecialities[i].speciality === speciality) {
          id = specialitiesData.getSpecialities[i].id;
          break;
        }
      }

      setSpecialities([id]);
    }
  }, [specialitiesData, speciality]);

  useEffect(() => {
    if (specialities.length > 0) fetchLawyers();
  }, [fetchLawyers, specialities]);
  ////// aol/speciality handler ^

  ///// Text and url fetch:
  const fetchSpecialityTextFile = async (fileName) => {
    const txtFileLocation = `/specialitiesCopyText/${fileName}.txt`;

    const response = await fetch(txtFileLocation);
    const text = await response.text();

    if (!text.includes('Meta-Description (de)')) return false;

    return text;
  };

  function parseTextFiles(text) {
    let textArray = text.split('\n');
    textArray = textArray.filter((el) => el !== '\r');

    let filedata = {
      de: {},
      en: {},
      ro: {},
    };

    filedata.de.metaTitle = '';
    filedata.de.metaDescription = '';
    filedata.de.text = '';
    filedata.en.metaTitle = '';
    filedata.en.metaDescription = '';
    filedata.en.text = '';

    const titles = [
      'Meta-Titel (de)',
      'Meta-Description (de)',
      'Text (de)',
      'Meta-Titel (en)',
      'Meta-Description (en)',
      'Text (en)',
      'Meta-Titel (ro)',
      'Meta-Description (ro)',
      'Text (ro)',
    ];

    let index = 0;

    for (let i = 0; i < textArray.length; i++) {
      if (textArray[i].includes(titles[index])) {
        let text = '';
        index++;

        while (
          i < textArray.length - 1 &&
          !textArray[i + 1].includes(titles[index])
        ) {
          text += textArray[i + 1] + '\n\n';
          i++;
        }

        if (index === 1) filedata.de.metaTitle = text.trim();
        if (index === 2) filedata.de.metaDescription = text.trim();
        if (index === 3) filedata.de.text = text.trim();
        if (index === 4) filedata.en.metaTitle = text.trim();
        if (index === 5) filedata.en.metaDescription = text.trim();
        if (index === 6) filedata.en.text = text.trim();
        if (index === 7) filedata.ro.metaTitle = text.trim();
        if (index === 8) filedata.ro.metaDescription = text.trim();
        if (index === 9) filedata.ro.text = text.trim();
      }
    }

    setFileData(filedata);
  }

  useEffect(() => {
    //!!! TO DO: This can cause issues with the MetaDecorator component
    if (!isBrowser()) return;

    let url = window.location.href;

    url = decodeURIComponent(url.split('/').pop());

    const fileName = url.split('/').pop();

    let getData = async () => {
      let name = fileName.replace(/--/g, '#');
      name = name.replace(/-/g, ' ');
      name = name.replace(/#/g, '- ');

      let specialityText = await fetchSpecialityTextFile(name);

      if (specialityText) {
        setType('speciality');

        parseTextFiles(specialityText);

        setSpeciality(name);
      } else {
        navigate('/*');
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ///// Text and url fetch ^

  // Navbar functionality:

  const handleSearchButton = () => {
    let navigateUrl = `/suche?`;

    if (searchInput) navigateUrl += `src=${searchInput}&`;

    if (locationInput) navigateUrl += `loc=${locationInput}&`;

    if (locationDistanceInput)
      navigateUrl += `srcdis=${locationDistanceInput}&`;

    if (areasOfLaw.length > 0) navigateUrl += `aol=${areasOfLaw}&`;

    navigateUrl = navigateUrl.slice(0, -1);

    navigate(navigateUrl);
  };

  const areaOfLawChangeHandler = (index) => {
    if (areasOfLaw.includes(index)) {
      setAreasOfLaw(areasOfLaw.filter((element) => element !== index));
    } else {
      setAreasOfLaw([...areasOfLaw, index]);
    }
  };

  const handleEnterKeyPress = (event) => {
    handleSearchButton();
  };

  ////// NavBar functionality ^

  return (
    <div className="lawp-home-page-container">
      <MetaDecorator
        title={fileData[language].metaTitle}
        description={fileData[language].metaDescription}
        canonical={'canonical'}
        link={isBrowser() ? window.location.href : 'canonical'}
      />

      <LandingPageTopView lawyerLoading={false} lawyerError={false} />

      <SearchBarComponent
        updateLawyers={handleSearchButton}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        locationInput={locationInput}
        setLocationInput={setLocationInput}
        locationDistanceInput={locationDistanceInput}
        onChangeLocationDistanceSlider={setLocationDistanceInput}
        areasOfLawBoxes={areasOfLaw}
        onChangeAreasOfLaw={areaOfLawChangeHandler}
        onEnterKeyPress={handleEnterKeyPress}
      />

      <div
        className="video-container"
        style={{
          marginTop: '30px',
        }}
      >
        <div className="video-container-info">
          {type === 'speciality' && (
            <h4>{LPTranslate('AOLS_Lawyer_Speciality')}</h4>
          )}

          {type === 'areaOfLaw' && (
            <h4>{LPTranslate('AOLS_Lawyer_Area_of_law')}</h4>
          )}

          <h2>{speciality}</h2>

          <h3
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            {fileData[language].text}
          </h3>
        </div>
      </div>

      <div id="lawyerSearchContainer" className={styles.lawyerSearchContainer}>
        <div className={styles.lawyerFilterContainer}>
          <Filters
            lawyerLoading={loading}
            lawyerError={error}
            filters={filterCheckBoxes}
            setFilterCheckBoxes={setFilterCheckBoxes}
            clearFilters={clearFilters}
            resultsType={resultsType}
            removeClearFilterButtonIfNoFilters={true}
          />
        </div>
        <div className={styles.lawyerCardsContainer}>
          <div className={styles.hamburgerMenu}>
            <FiltersDrawer
              filters={filterCheckBoxes}
              setFilterCheckBoxes={setFilterCheckBoxes}
              clearFilters={clearFilters}
              resultsType={resultsType}
              removeClearFilterButtonIfNoFilters={true}
            />
          </div>

          <div className={styles.lawyerCards}>
            <div className={styles.lawyerCardsResultsContainer}>
              {loading ? (
                Array.from(new Array(10)).map((value, key) => (
                  <LawyerCardSkeleton key={key} />
                ))
              ) : lawyers.length > 0 ? (
                lawyers.map((lawyer, key) => (
                  <LawyerCard key={lawyer.id} lawyer={lawyer} />
                ))
              ) : (
                <p
                  className={styles['lawp-article-blog-page-no-lawyers-text']}
                  data-cy="no-lawyers-found"
                >
                  {LPTranslate('LLP_No_Lawyers_Found')}
                </p>
              )}
            </div>

            {lawyers.length > 0 && pageCount > 1 && (
              <div
                className={
                  styles['lawp-article-blog-page-articles-pagination-container']
                }
              >
                <Button
                  disableRipple
                  data-cy="back-button"
                  disabled={page === 1}
                  className={styles['lawp-article-blog-page-left-arrow-button']}
                  startIcon={
                    page === 1 ? null : <img src={leftArrow} alt="leftArrow" />
                  }
                  onClick={() => {
                    handlePageChange(page - 1);
                  }}
                >
                  {page === 1 ? '' : LPTranslate('Button_Back')}
                </Button>

                <Stack>
                  <Pagination
                    className="lawp-article-blog-page-pagination"
                    count={pageCount}
                    page={page}
                    onChange={(event, value) => {
                      handlePageChange(value);
                    }}
                  />
                </Stack>

                <Button
                  disableRipple
                  data-cy="next-button"
                  disabled={page === pageCount}
                  className={styles['lawp-article-blog-page-left-arrow-button']}
                  endIcon={
                    page === pageCount ? null : (
                      <img src={rightArrow} alt="leftArrow" />
                    )
                  }
                  onClick={() => handlePageChange(page + 1)}
                >
                  {page === pageCount ? '' : LPTranslate('Button_Next')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LawyerSpecialityPage;
