import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import 'Assets/styles/LoadingScreen.scss';

const LoadingScreen = () => {
  return (
    <div className="lawp-loading-screen">
      <CircularProgress color="secondary" />
    </div>
  );
};

export default LoadingScreen;
