import React from 'react';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/LegalDirectoryPage.scss';

const SitemapPage = (props) => {
  return (
    <div className="lawp-legal-directory-page">
      <h1>{LPTranslate('Common_Legal_Resources_Hub')}</h1>

      <a href={`${process.env.REACT_APP_DNS_URI}/legal-directory/cities`}>
        {LPTranslate('Common_Cities')}
      </a>

      <a href={`${process.env.REACT_APP_DNS_URI}/legal-directory/areas-of-law`}>
        {LPTranslate('Common_Areas_Of_Law')}
      </a>

      <a href={`${process.env.REACT_APP_DNS_URI}/legal-directory/specialities`}>
        {LPTranslate('Common_Specialities')}
      </a>

      <a href={`${process.env.REACT_APP_DNS_URI}/legal-directory/articles`}>
        {LPTranslate('Common_Articles')}
      </a>

      <a href={`${process.env.REACT_APP_DNS_URI}/legal-directory/lawyers`}>
        {LPTranslate('Common_Lawyers')}
      </a>
    </div>
  );
};

export default SitemapPage;
