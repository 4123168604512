import React from 'react';

import { Route, Routes } from 'react-router-dom';

import SitemapLawyerPage from '../Pages/SitemapLawyerPage';

import LandingPage from '../Pages/LandingPage';
// import LawyerAddPage from '../Pages/LaywerAddPage';
// import ClientAddPage from '../Pages/ClientAddPage';
// import UserClientPage from '../Pages/UserClientPage';
import LoginPage from '../Pages/LoginPage';
import LawyerPage from '../Pages/LawyerPage';
import ArticlesBlogPage from '../Pages/ArticlesBlogPage';
// import UserOperatorPage from '../Pages/UserOperatorPage';
// import OperatorAddPage from '../Pages/OperatorAddPage';
import RegisterClientPage from '../Pages/RegisterClientPage';
// import RegisterLawyerPage from '../Pages/RegisterLawyerPage';
import RegisterLawyerPage from 'Components/RegisterLawyer/NewRegister/RegisterLawyerPage';
// import RegisterOperatorPage from '../Pages/RegisterOperatorPage';
// import AccountVerificationPage from '../Pages/AccountVerificationPage';
import UpdateLawyerPage from '../Pages/UpdateLawyerPage';
// import MessagingPage from '../Pages/MessagingPage';
// import ForgotPasswordPage from '../Pages/ForgotPasswordPage';
// import ResetPasswordPage from '../Pages/ResetPasswordPage';
import ResetForgotPaswwordPage from 'Pages/ResetForgotPasswordPage';
import ErrorPage from '../Pages/ErrorPage';
// import WebRTCTestPage from '../Pages/WebRTCTestPage';
// import CallPage from '../Pages/CallPage';
import NavBarComponent from 'Layouts/NavBarComponent';
import Footer from 'Layouts/footer';
// import DeletAccountPage from '../Pages/DeleteAccountPage';
// import SubscriptionProcessPage from '../Pages/SubscriptionProcessPage';
import NewSubscriptionProcessPage from '../Pages/NewSubscriptionProcessPage';
import SubscriptionPage from '../Pages/SubscriptionPage';
// import NewSubscriptionPage from '../Pages/NewSubscriptionPage';
import UpdateLawyerDetailsPage from '../Pages/UpdateLawyerDetailsPage';
import ArticleBlogPageDetails from '../Pages/ArticleBlogPageDetails';
import UpdateClientDetailsPage from '../Pages/UpdateClientDetailsPage';
import ClientProfileSettings from 'Components/Client/Settings/ClientProfileSettings';
import LawyerCalendarSettingsPage from '../Pages/LawyerCalendarSettingsPage';
import ClientFavoriteLawyer from '../Pages/ClientFavoriteLawyerPage';
import ClientReviews from '../Pages/ClientReviews';
import LawyerReviews from '../Pages/LawyerReviews';
import EventsPage from '../Pages/EventsPage';
// import CookiesPage from '../Pages/CookiesPage';
import ScoringPoliciesPage from '../Pages/ScoringPoliciesPage';
import PrivacyPage from '../Pages/PrivacyPage';
import TermsOfServicePage from '../Pages/TermsOfServicePage';
import ImprintPage from '../Pages/ImprintPage';
import FAQPage from '../Pages/FAQPage';
import AboutUsPage from '../Pages/AboutUsPage';
import ContactUsPage from '../Pages/ContactUsPage';
import AnwadoSearchPage from '../Pages/AnwadoSearchPage';
import PolicyPage from '../Pages/PolicyPage';
import HomePage from '../Pages/HomePage';
import HomePageLawyer from '../Pages/HomePageLawyer';
import PricePage from '../Pages/PricePage';
import OperatorAccountPage from '../Pages/OperatorAccountPage';
import OperatorArticlePage from '../Pages/OperatorArticlePage';
import OperatorReviewReportsPage from '../Pages/OperatorReviewReportsPage';
import OperatorSocialMedia from 'Pages/OperatorSocialMedia';
import OperatorStripeInvoicesPage from 'Pages/OperatorStripeInvoicesPage';
import LawyerSpecialityPage from '../Pages/LawyerSpecialityPage';
import LawyerCityPage from '../Pages/LawyerCityPage';
import LawyerAOLPage from '../Pages/LawyerAOLPage';
import LawyerArticlesPage from '../Pages/LawyerArticlesPage';
import LegalDirectoryPage from '../Pages/LegalDirectoryPage';
import LegalDirectoryDetailsPage from '../Pages/LegalDirectoryDetailsPage';

import {
  AuthenticationRoute,
  LawyerAuthenticationRoute,
  OperatorAuthenticationRoute,
  ClientAuthenticationRoute,
} from './AuthenticationRoute';
import SetMeetingBetweenClientAndLawyerPage from 'Pages/SetMeetingBetweenClientAndLawyerPage';
import SubscriptionStep from 'Components/RegisterLawyer/SubscriptionStep';
import DeleteLawyerPage from 'Pages/DeleteLawyerPage';

const Router = (props) => {
  return (
    <Routes>
      {/* sitemaps */}
      <Route exact path="/sitemap/sitemap2.xml">
        <Route path="" element={<SitemapLawyerPage />} />
      </Route>
      {/* fara navbar si footer */}
      <Route path="/registerClient/">
        <Route
          path=""
          element={
            <>
              <NavBarComponent />
              <RegisterClientPage />
              <Footer />
            </>
          }
        />
        <Route
          path=":token"
          element={
            <>
              <NavBarComponent />
              <RegisterClientPage />
              <Footer />
            </>
          }
        />
      </Route>
      <Route path="/registerLawyer/">
        <Route
          path=""
          element={
            <>
              <NavBarComponent />
              <RegisterLawyerPage />
              <Footer />
            </>
          }
        />
        <Route
          path=":token"
          element={
            <>
              <NavBarComponent />
              <RegisterLawyerPage />
              <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/deleteLawyer/:token">
        <Route
          path=""
          element={
            <>
              <DeleteLawyerPage />
            </>
          }
        />
      </Route>

      {/* <Route
        exact
        path="/deleteAccount"
        element={
          <>
            <NavBarComponent />
            <DeletAccountPage />
            <Footer />
          </>
        }
      /> */}

      {/* <Route
        exact
        path="/verify/:token"
        element={
          <>
            <NavBarComponent />
            <AccountVerificationPage />
            <Footer />
          </>
        }
      /> */}
      {/* <Route
        exact
        path="/registerOperator"
        element={<RegisterOperatorPage />}
      /> */}
      {/* <Route
        exact
        path="/resetPassword/:token"
        element={
          <>
            <NavBarComponent />
            <ResetPasswordPage />
            <Footer />
          </>
        }
      /> */}
      <Route path="/resetForgotPassword/">
        <Route
          path=""
          element={
            <>
              <NavBarComponent />
              <ResetForgotPaswwordPage />
              <Footer />
            </>
          }
        />
        <Route
          path=":forgotPasswordToken"
          element={
            <>
              <NavBarComponent />
              <ResetForgotPaswwordPage />
              <Footer />
            </>
          }
        />
      </Route>

      {/* <Route
        exact
        path="/forgotPassword"
        element={
          <>
            <NavBarComponent />
            <ForgotPasswordPage />
            <Footer />
          </>
        }
      /> **/}

      <Route
        exact
        path="/login"
        element={
          <>
            <NavBarComponent />
            <LoginPage />
            <Footer />
          </>
        }
      />
      {/* <Route
        exact
        path="/addLawyer"
        element={
          <>
            <NavBarComponent />
            <LawyerAddPage />
            <Footer />{' '}
          </>
        }
      />
      <Route
        exact
        path="/addClient"
        element={
          <>
            <NavBarComponent />
            <ClientAddPage />
            <Footer />
          </>
        }
      />
      <Route
        exact
        path="/addOperator"
        element={
          <>
            <NavBarComponent />
            <OperatorAddPage />
            <Footer />{' '}
          </>
        }
      /> */}

      <Route
        exact
        path="/suche"
        element={
          <>
            <NavBarComponent />
            <LandingPage />
            <Footer />
          </>
        }
      />
      <Route
        exact
        path="/"
        element={
          <>
            <NavBarComponent />
            <HomePage sitemaps={props.sitemaps} />
            <Footer />
          </>
        }
      />

      <Route
        exact
        path="/legal-directory"
        element={
          <>
            <NavBarComponent />
            <LegalDirectoryPage />
            <Footer />
          </>
        }
      />

      <Route
        exact
        path="/legal-directory/:slug"
        element={
          <>
            <NavBarComponent />
            <LegalDirectoryDetailsPage sitemaps={props.sitemaps} />
            <Footer />
          </>
        }
      />

      <Route
        exact
        path="/subscriptionStep"
        element={
          <>
            <NavBarComponent />
            <SubscriptionStep />
            <Footer />
          </>
        }
      />

      <Route
        exact
        path="/anwaelte"
        element={
          <>
            <NavBarComponent />
            <HomePageLawyer />
            <Footer />
          </>
        }
      />

      <Route
        exact
        path="/anwaelte/:lawyerSlug"
        element={
          <>
            <NavBarComponent />
            <LawyerPage />
            <Footer />
          </>
        }
      />

      {/* <Route
        path="/"
        element={
          <>
            <NavBarComponent />
            <AuthenticationRoute />
            <Footer />
          </>
        }
      >
        <Route
          exact
          path="/messaging"
          element={
            <>
              <NavBarComponent />
              <MessagingPage />
              <Footer />
            </>
          }
        />
      </Route> */}

      <Route exact path="/" element={<AuthenticationRoute />}>
        {/* LOGGED IN MIDDLEWARE START */}
        <Route
          exact
          path="/favoriteLawyer"
          element={
            <>
              <NavBarComponent />
              <ClientFavoriteLawyer />
              <Footer />
            </>
          }
        />

        <Route
          exact
          path="/eventSettings"
          element={
            <>
              <NavBarComponent />
              <EventsPage />
              <Footer />
            </>
          }
        />
        {/* LOGGED IN MIDDLEWARE END */}
      </Route>

      <Route exact path="/" element={<OperatorAuthenticationRoute />}>
        {/* OPERATOR MIDDLEWARE START */}
        <Route
          exact
          path="/operatorSocialMedia"
          element={
            <>
              <NavBarComponent />
              <OperatorSocialMedia />
              <Footer />
            </>
          }
        />

        <Route
          exact
          path="/operatorStripeInvoices"
          element={
            <>
              <NavBarComponent />
              <OperatorStripeInvoicesPage />
              <Footer />
            </>
          }
        />

        <Route
          exact
          path="/operatorReviewReports"
          element={
            <>
              <NavBarComponent />
              <OperatorReviewReportsPage />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/OperatorAccount"
          element={
            <>
              <NavBarComponent />
              <OperatorAccountPage />
              <Footer />
            </>
          }
        />

        <Route
          exact
          path="/OperatorArticles"
          element={
            <>
              <NavBarComponent />
              <OperatorArticlePage />
              <Footer />
            </>
          }
        />
        {/* OPERATOR MIDDLEWARE END */}
      </Route>

      <Route exact path="/" element={<LawyerAuthenticationRoute />}>
        {/* LAWYER MIDDLEWARE START */}
        <Route
          exact
          path="/updateLawyerReviews"
          element={
            <>
              <NavBarComponent />
              <LawyerReviews />
              <Footer />
            </>
          }
        />

        <Route
          exact
          path="/updateLawyerArticles"
          element={
            <>
              <NavBarComponent />
              <LawyerArticlesPage />
              <Footer />
            </>
          }
        />

        <Route
          exact
          path="/paymentSettings"
          element={
            <>
              <NavBarComponent />
              <SubscriptionPage />
              <Footer />
            </>
          }
        />

        <Route
          exact
          path="/updateLawyerPage"
          element={
            <>
              <NavBarComponent />
              <UpdateLawyerPage />
              <Footer />
            </>
          }
        />

        <Route
          path="/calendarSettings"
          element={
            <>
              <NavBarComponent /> <LawyerCalendarSettingsPage /> <Footer />
            </>
          }
        />

        <Route exact path="/updateLawyerDetails/">
          <Route
            path=""
            element={
              <>
                <NavBarComponent /> <UpdateLawyerDetailsPage /> <Footer />
              </>
            }
          />
          <Route
            path=":token"
            element={
              <>
                <NavBarComponent /> <UpdateLawyerDetailsPage /> <Footer />
              </>
            }
          />
        </Route>

        {/* LAWYER MIDDLEWARE END */}
      </Route>

      <Route exact path="/" element={<ClientAuthenticationRoute />}>
        {/* CLIENT MIDDLEWARE START */}
        <Route
          exact
          path="/updateClientProfile"
          element={
            <>
              <NavBarComponent />
              <ClientProfileSettings />
              <Footer />
            </>
          }
        />

        <Route
          exact
          path="/updateClientReviews"
          element={
            <>
              <NavBarComponent />
              <ClientReviews />
              <Footer />
            </>
          }
        />

        {/* CLIENT MIDDLEWARE END */}
      </Route>

      <Route
        exact
        path="/updateClientCredentials/"
        element={<AuthenticationRoute />}
      >
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <UpdateClientDetailsPage /> <Footer />
            </>
          }
        />
        <Route
          path=":token"
          element={
            <>
              <NavBarComponent /> <UpdateClientDetailsPage /> <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/fachanwalt/:speciality">
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <LawyerSpecialityPage /> <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/ort/:city">
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <LawyerCityPage /> <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/aol/:aol">
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <LawyerAOLPage /> <Footer />
            </>
          }
        />
      </Route>

      {/* <Route exact path="/cookiesPolicy">
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <CookiesPage /> <Footer />
            </>
          }
        />
      </Route> */}

      <Route exact path="/bewertungsrichtlinien">
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <ScoringPoliciesPage /> <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/datenschutz-cookies">
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <PrivacyPage /> <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/agb">
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <TermsOfServicePage /> <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/impressum">
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <ImprintPage /> <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/faq">
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <FAQPage /> <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/anwado">
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <AboutUsPage /> <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/kontakt">
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <ContactUsPage /> <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/qualitaetsversprechen">
        <Route
          path=""
          element={
            <>
              <NavBarComponent /> <PolicyPage /> <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/">
        <Route
          exact
          path="/setAppointment"
          element={
            <>
              <NavBarComponent />
              <SetMeetingBetweenClientAndLawyerPage />
              <Footer />
            </>
          }
        />
      </Route>

      {/* <Route exact path="/" element={<AuthenticationRoute />}>
        <Route
          exact
          path="/verifyToken"
          element={
            <>
              <NavBarComponent />
              <UpdateLawyerDetailsPage />
              <Footer />
            </>
          }
        />
      </Route> */}

      {/* <Route exact path="/" element={<AuthenticationRoute />}>
        <Route
          exact
          path="/webrtctest"
          element={
            <>
              <NavBarComponent />
              <WebRTCTestPage />
              <Footer />
            </>
          }
        />
      </Route> */}

      {/* <Route exact path="/" element={<AuthenticationRoute />}>
        <Route
          exact
          path="/call/:roomId"
          element={
            <>
              <NavBarComponent />
              <CallPage />
              <Footer />
            </>
          }
        />
      </Route> */}

      <Route exact path="/" element={<AuthenticationRoute />}>
        <Route
          exact
          path="/subscriptionPage"
          element={
            <>
              <NavBarComponent />
              <NewSubscriptionProcessPage />
              <Footer />
            </>
          }
        />
      </Route>

      <Route exact path="/">
        <Route
          exact
          path="/preise"
          element={
            <>
              <NavBarComponent />
              <PricePage />
              <Footer />
            </>
          }
        />
      </Route>

      {/* not used */}
      {/* <Route
        exact
        path="/"
        element={
          <>
            <NavBarComponent />
            <AuthenticationRoute />
            <Footer />
          </>
        }
      >
        <Route
          exact
          path="/userPageClient"
          element={
            <>
              <NavBarComponent />
              <UserClientPage />
              <Footer />
            </>
          }
        />
      </Route>

      <Route
        exact
        path="/"
        element={
          <>
            <NavBarComponent />
            <AuthenticationRoute />
            <Footer />
          </>
        }
      >
        <Route
          exact
          path="/userPageOperator"
          element={
            <>
              <NavBarComponent />
              <UserOperatorPage />
              <Footer />
            </>
          }
        />
      </Route> */}

      <Route
        exact
        path="/rechtsinfos"
        element={
          <>
            <NavBarComponent />
            <ArticlesBlogPage />
            <Footer />
          </>
        }
      />

      <Route
        exact
        path="/rechtsinfos/:articleSlug"
        element={
          <>
            <NavBarComponent />
            <ArticleBlogPageDetails />
            <Footer />
          </>
        }
      />

      <Route
        exact
        path="/anwado-suche"
        element={
          <>
            <NavBarComponent />
            <AnwadoSearchPage />
            <Footer />
          </>
        }
      />

      <Route
        path="*"
        element={
          <>
            <NavBarComponent />
            <ErrorPage />
            <Footer />
          </>
        }
      />
    </Routes>
  );
};

export default Router;
