const getSitemap = async (slug) => {
  const response = await fetch(
    process.env.REACT_APP_DNS_URI + '/sitemap-data?sitemap=' + slug
  );

  const sitemap = await response.json();

  return sitemap;
};

export { getSitemap };
