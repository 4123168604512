import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { getSitemap } from 'Services/Utils/sitemap';

import 'Assets/styles/LegalDirectoryDetailsPage.scss';

// const tempData = [
//   'http://localhost:3002/ort/Berlin',
//   'http://localhost:3002/ort/Constanta',
//   'http://localhost:3002/ort/Roșiorii-de-Vede',
//   'http://localhost:3002/ort/Bucharest',
//   'http://localhost:3002/ort/Brasov',
//   'http://localhost:3002/ort/11',
//   'http://localhost:3002/ort/München',
//   'http://localhost:3002/ort/Roșiori-de-Vede',
//   'http://localhost:3002/ort/Satu-Mare',
//   'http://localhost:3002/ort/Oradea',
//   'http://localhost:3002/ort/Mumbai',
//   'http://localhost:3002/ort/London',
//   'http://localhost:3002/ort/Lima',
//   'http://localhost:3002/ort/111',
//   'http://localhost:3002/ort/New-York',
//   'http://localhost:3002/ort/Berlin',
//   'http://localhost:3002/ort/Constanta',
//   'http://localhost:3002/ort/Roșiorii-de-Vede',
//   'http://localhost:3002/ort/Bucharest',
//   'http://localhost:3002/ort/Brasov',
//   'http://localhost:3002/ort/11',
//   'http://localhost:3002/ort/München',
//   'http://localhost:3002/ort/Roșiori-de-Vede',
//   'http://localhost:3002/ort/Satu-Mare',
//   'http://localhost:3002/ort/Oradea',
//   'http://localhost:3002/ort/Mumbai',
//   'http://localhost:3002/ort/London',
//   'http://localhost:3002/ort/Lima',
//   'http://localhost:3002/ort/111',
//   'http://localhost:3002/ort/New-York',
//   'http://localhost:3002/ort/Berlin',
//   'http://localhost:3002/ort/Constanta',
//   'http://localhost:3002/ort/Roșiorii-de-Vede',
//   'http://localhost:3002/ort/Bucharest',
//   'http://localhost:3002/ort/Brasov',
//   'http://localhost:3002/ort/11',
//   'http://localhost:3002/ort/München',
//   'http://localhost:3002/ort/Roșiori-de-Vede',
//   'http://localhost:3002/ort/Satu-Mare',
//   'http://localhost:3002/ort/Oradea',
//   'http://localhost:3002/ort/Mumbai',
//   'http://localhost:3002/ort/London',
//   'http://localhost:3002/ort/Lima',
//   'http://localhost:3002/ort/111',
//   'http://localhost:3002/ort/New-York',
// ];

const getUrlName = (url, slug) => {
  let parameter = url.replace(/--/g, '#');
  parameter = parameter.replace(/-/g, ' ');
  parameter = parameter.replace(/#/g, '- ');

  const urlArray = parameter.split('/');

  let name = urlArray[urlArray.length - 1];

  if (slug === 'lawyers') {
    name = name.substring(0, name.lastIndexOf(' '));

    name = name.replace(/\b\w/g, (l) => l.toUpperCase());
  }

  return name;
};

const groupAndSortUrls = (urls, slug) => {
  const grouped = {};

  urls
    .map((url) => ({ url, name: getUrlName(url, slug) }))
    .sort((a, b) => a.name.localeCompare(b.name))
    .forEach(({ url, name }) => {
      const initial = name.charAt(0).toUpperCase();
      if (!grouped[initial]) {
        grouped[initial] = [];
      }
      grouped[initial].push({ url, name });
    });

  return grouped;
};

const headerText = (slug) => {
  switch (slug) {
    case 'cities':
      return 'Cities';
    case 'areas-of-law':
      return 'Areas of Law';
    case 'specialities':
      return 'Specialities';
    case 'lawyers':
      return 'Lawyers';
    case 'articles':
      return 'Articles';
    default:
      return 'Legal Directory Details';
  }
};

const LegalDirectoryDetailsPage = (props) => {
  const [legalDirectoryDetails, setLegalDirectoryDetails] = useState(
    props.sitemaps || null
  );

  const { slug } = useParams();

  useEffect(() => {
    if (!legalDirectoryDetails) {
      getSitemap(slug).then((data) => {
        setLegalDirectoryDetails(data);
      });

      // setLegalDirectoryDetails(tempData);
    }
  }, [legalDirectoryDetails, slug]);

  const groupedUrls = legalDirectoryDetails
    ? groupAndSortUrls(legalDirectoryDetails, slug)
    : {};

  return (
    <div className="lawp-legal-directory-details-page">
      <h1>{headerText(slug)}</h1>

      {Object.keys(groupedUrls).length > 0 && (
        <div className="lists-container">
          {Object.keys(groupedUrls).map((initial) => (
            <div className="list-container" key={initial}>
              <h2>{initial}</h2>
              <ul>
                {groupedUrls[initial].map(({ url, name }, index) => (
                  <li key={index}>
                    <a href={url}>{name}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LegalDirectoryDetailsPage;
