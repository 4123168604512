import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import moment from 'moment';

import CircleRadioFilter from 'Components/Filters/CircleRadioFilter.js';
import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { SET_APPOINTMENT } from 'Services/Queries/client';
import { tranformToCalendarTimestamp } from 'Services/Utils/calendar/calendar';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import arrowLeft from 'Assets/pictures/purple-left-arrow.svg';
import 'Assets/styles/lawyer/SetMeetingBetweenClientAndLawyerModal.scss';

const SetMeetingDetailsBetweenClientAndLawyerModal = (props) => {
  const {
    nextStepDate,
    nextStepHour,
    meetingVal,
    formValues,
    formErrors,
    // errorMessage,
  } = props;
  const [setAppointment, { data: setAppointmentData }] =
    useMutation(SET_APPOINTMENT);

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [meetingOptions, setMeetingOptions] = useState([]);

  // const indexWeekday = [
  //   LPTranslate('Common2_Monday'),
  //   LPTranslate('Common2_Tuesday'),
  //   LPTranslate('Common2_Wednesday'),
  //   LPTranslate('Common2_Thursday'),
  //   LPTranslate('Common2_Friday'),
  //   LPTranslate('Common2_Saturday'),
  //   LPTranslate('Common2_Sunday'),
  // ];

  // const weekdaysGerman = {
  //   Sunday: 'Sonntag',
  //   Monday: 'Montag',
  //   Tuesday: 'Dienstag',
  //   Wednesday: 'Mittwoch',
  //   Thursday: 'Donnerstag',
  //   Friday: 'Freitag',
  //   Saturday: 'Samstag',
  // };

  const onChangeSubject = (value) => {
    props.setFormValues({ ...formValues, title: value });
  };

  const onChangeDescriptionValue = (value) => {
    props.setFormValues({ ...formValues, description: value });
  };

  const isSubmitDisabled = () => {
    if (
      formValues.title.length === 0 ||
      formValues.description.length === 0 ||
      meetingVal === ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    let meetingOptions = [];
    let id = 1;

    if (props.availability.by_phone && formValues.phoneNumber) {
      meetingOptions.push({
        _id: id,
        name: LPTranslate('LSA_Update_Appointment_Call'),
        value: 'phone',
        checked: false,
      });
      id++;
    }

    //add && formValues.addressValue aici?
    if (props.availability.personally) {
      meetingOptions.push({
        _id: id,
        name: LPTranslate('LSA_Update_Appointment_Site_Meeting'),
        value: 'address',
        checked: true,
      });
      id++;
    }

    setMeetingOptions(meetingOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModal = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = {};

    if (formValues.title.length === 0) {
      isError = true;
      newInputErrors.title = LPTranslate('Error_Meeting_Title_Required');
    }

    if (formValues.description.length === 0) {
      isError = true;
      newInputErrors.description = LPTranslate(
        'Error_Meeting_Message_Required'
      );
    }

    if (meetingVal === '') {
      isError = true;
      newInputErrors.meetingVal = LPTranslate('Error_Metting_Type_Required');
    }

    props.setFormErrors(newInputErrors);

    if (isError) return;

    const descriptionArr = formValues.description.split(/\n/g);

    let gmailDescription,
      outlookDescription,
      yahooDescription,
      icsDescription = '';

    for (let i = 0; i < descriptionArr.length; i++) {
      if (i === 0) {
        gmailDescription = '<div>' + descriptionArr[i] + '</div>\n';
        outlookDescription = '<div>' + descriptionArr[i] + '</div>\n';
        yahooDescription = descriptionArr[i];
        icsDescription = descriptionArr[i];
      } else {
        if (descriptionArr[i] === '') {
          gmailDescription += '<p></p>\n';
          outlookDescription += '<br>';
        } else gmailDescription += '<div>' + descriptionArr[i] + '</div>\n';
        outlookDescription += '<div>' + descriptionArr[i] + '</div>\n';
        yahooDescription += '%0d%0a' + descriptionArr[i];
        icsDescription += '\n' + descriptionArr[i];
      }
    }

    if (clientInfoRedux.token === '') props.setState(2);
    else {
      // am nevoie de o singura variabila pentru data programarii
      // DD MM YYYY HH:mm, de aici pot sa iau ziua respectiva/ sau direct din nextStepDate
      // pot sa adaug 30 de min pentru end appointment
      // pot sa transform in timestamp data
      const hour = nextStepHour.split(':')[0];
      const minute = nextStepHour.split(':')[1];

      // const weekday = weekdaysGerman[nextStepDate.split(',')[0]];
      const dateString = nextStepDate.split(',')[1].split(' ')[1].split('.');
      const day = dateString[0];
      const month = dateString[1];
      const year = dateString[2];

      const dateMoment = moment(`${day} ${month} ${year}`, 'DD MM YYYY');

      let appointmentDateTimestamp = moment(dateMoment).format('X');

      // let messageDate =
      //   weekdaysGerman[moment().format('dddd')] +
      //   ', ' +
      //   moment().format('DD.MM.YYYY');

      let startAppointment = moment(dateMoment).set({
        hour: hour,
        minute: minute,
      });
      let endAppointment = moment(dateMoment)
        .set({
          hour: hour,
          minute: minute,
        })
        .add('30', 'minutes');

      let startAppointmentTimestamp =
        tranformToCalendarTimestamp(startAppointment);

      let endAppointmentTimestamp = tranformToCalendarTimestamp(endAppointment);

      let addressValue = null;

      if (formValues.addressValue) {
        addressValue =
          formValues.addressValue.street +
          ' ' +
          formValues.addressValue.house_number +
          ', ' +
          formValues.addressValue.city +
          ', ' +
          formValues.addressValue.zipcode +
          ', ' +
          formValues.addressValue.country;
      }

      try {
        await setAppointment({
          variables: {
            input: {
              lawyer_id: props.lawyer_id,
              client_id: props.client_id,
              title: formValues.title,
              appointmentLocation: addressValue,
              gmailDescription: gmailDescription,
              outlookDescription: outlookDescription,
              yahooDescription: yahooDescription,
              icsDescription: JSON.stringify(icsDescription).replace(/"/g, ''),
              meetingVal: meetingOptions[meetingVal].name,
              phoneNumber: formValues.phoneNumber,
              firstName: clientInfoRedux.first_name,
              lastName: clientInfoRedux.last_name,
              email: clientInfoRedux.email,
              appointmentHour: nextStepHour,
              role: clientInfoRedux.role,
              nextStepDate: nextStepDate,
              // weekday: weekday,
              dayNumber: day,
              month: month,
              year: year,
              appointmentDateTimestamp: appointmentDateTimestamp,
              startAppointmentTimestamp: startAppointmentTimestamp,
              endAppointmentTimestamp: endAppointmentTimestamp,
              updateAppointment: false,
              description: formValues.description,
              // messageDate: messageDate,
              messageName:
                clientInfoRedux.first_name + ' ' + clientInfoRedux.last_name,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (setAppointmentData && setAppointmentData.setAppointment.message) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'client-set-appointment-custom-event',
      });
      props.setState(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAppointmentData]);

  return (
    <div className="lawp-add-meeting-modal-container-right-content-form">
      <div className="lawp-add-meeting-modal-container-right-frame1038">
        <button
          className={
            'lawp-add-meeting-modal-container-right-row-arrow-button-back'
          }
          onClick={() => props.setState(0)}
        >
          <img src={arrowLeft} alt="arrow" />
        </button>
      </div>
      <div className="lawp-add-meeting-modal-container-right-heading-and-supporting-text">
        <h1 className="lawp-add-meeting-modal-container-right-heading">
          {LPTranslate('CSA_Title')}
        </h1>
        <h3 className="lawp-add-meeting-modal-container-right-supporting-text">
          {LPTranslate('CSA_Description_Step_2')}
        </h3>
      </div>
      <h3 className="lawp-add-meeting-modal-container-right-text">
        {`${nextStepDate} um ${nextStepHour} Uhr`}
      </h3>
      <SimpleTextField
        data_cy="title-input"
        title={LPTranslate('CSA_Topic_Title')}
        value={formValues.title}
        onChange={(value) => {
          onChangeSubject(value);
          props.setFormErrors({
            ...formErrors,
            title: '',
          });
        }}
        textFieldWidthPercent={100}
        type="text"
        maxLength={25}
        placeholder={LPTranslate('CSA_Topic_Placeholder')}
        errorMessage={formErrors.title}
      />
      <SimpleTextField
        data_cy="description-input"
        title={LPTranslate('Common_Message')}
        value={formValues.description}
        onChange={(value) => {
          onChangeDescriptionValue(value);
          props.setFormErrors({
            ...formErrors,
            description: '',
          });
        }}
        textFieldWidthPercent={100}
        multiline={true}
        rows={2.5}
        maxLength={255}
        type="text"
        hintText={LPTranslate('CSA_Message_Hint')}
        placeholder={LPTranslate('CSA_Create_Appointment_Message_Placeholder')}
        errorMessage={formErrors.description}
        hasHintText
      />
      <CircleRadioFilter
        filterOptions={meetingOptions}
        formValues={formValues}
        meetingVal={meetingVal}
        setMeetingVal={props.setMeetingVal}
        availability={props.availability}
      />
      {formErrors.meetingVal && (
        <p className="lawp-add-meeting-modal-error-message">
          {formErrors.meetingVal}
        </p>
      )}
      <button
        data-cy="submit-button"
        className={`${
          isSubmitDisabled()
            ? 'lawp-add-meeting-modal-container-right-submit-button-disabled'
            : 'lawp-add-meeting-modal-container-right-submit-button'
        }`}
        onClick={handleModal}
        disabled={isSubmitDisabled()}
      >
        {LPTranslate('Button_Make_Appointment')}
      </button>
    </div>
  );
};

export default SetMeetingDetailsBetweenClientAndLawyerModal;
