import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import AvatarImage1 from 'Assets/pictures/AvatarImage1.png';
import AvatarImage2 from 'Assets/pictures/AvatarImage2.png';
import AvatarImage3 from 'Assets/pictures/AvatarImage3.png';
import AvatarImage4 from 'Assets/pictures/AvatarImage4.png';
import AvatarImage5 from 'Assets/pictures/AvatarImage5.png';

//config
let marginValue = 16;
let avatarSize = 50;
const styles = {
  container: {
    paddingLeft: marginValue,
  },
  avatarGroupDirection: {
    flexDirection: 'row',
  },
  firstAvatarStyling: {
    marginLeft: -marginValue,
    height: avatarSize,
    width: avatarSize,
  },
  avatarStyling: {
    height: avatarSize,
    width: avatarSize,
  },
};

export default function AvatarRowGroup(props) {
  return (
    <div style={styles.container}>
      {props.lawyerLoading || props.lawyerError ? (
        <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{ mt: '8px', ml: '-16px' }}
          />
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{ ml: '-16px !important' }}
          />
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{ ml: '-16px !important' }}
          />
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{ ml: '-16px !important' }}
          />
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{ ml: '-16px !important' }}
          />
        </Stack>
      ) : (
        <AvatarGroup
          style={styles.avatarGroupDirection}
          total={5}
          max={5}
          spacing="small"
        >
          <Avatar
            style={styles.firstAvatarStyling}
            alt="Remy Sharp"
            src={String(AvatarImage5)}
          />
          <Avatar
            style={styles.avatarStyling}
            alt="Travis Howard"
            src={String(AvatarImage4)}
          />
          <Avatar
            style={styles.avatarStyling}
            alt="Agnes Walker"
            src={String(AvatarImage3)}
          />
          <Avatar
            style={styles.avatarStyling}
            alt="Trevor Henderson"
            src={String(AvatarImage2)}
          />
          <Avatar
            style={styles.avatarStyling}
            alt="Trevor Henderson"
            src={String(AvatarImage1)}
          />
        </AvatarGroup>
      )}
    </div>
  );
}
