import React from 'react';

import { useNavigate } from 'react-router-dom';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { createUrlSlug } from 'Services/Utils/metaData';

import styles from 'Assets/styles/articles/ArticleLawyerCard.module.scss';
import copyIcon from 'Assets/pictures/copyIconPurple.svg';
import operatorIcon from 'Assets/pictures/AnwadoLogoPurple.png';

const ArticleLawyerCard = (props) => {
  const {
    profImg,
    username,
    lawfirm,
    // link,
    // lawyerid,
    position,
    role,
    article_id,
    article_title,
  } = props;

  const navigate = useNavigate();
  const handleClick = () => {
    if (role === 'operator') navigate(`/`);
    else {
      let slug = createUrlSlug(
        '',
        props.lawyer_first_name,
        props.lawyer_last_name,
        ''
      );

      slug += `-${props.lawyer_id}`;
      navigate(`/anwaelte/${slug}`);
    }
  };

  return (
    <div className={styles['lawyer-card-container']}>
      {position === 'top' && <div className={styles['divider']} />}

      <div className={styles['lawyer-content-container']}>
        <div
          className={styles['avatar-label-group']}
          onClick={handleClick}
          data-cy="navigate-to-lawyer-button"
        >
          <img
            className={styles['avatar-picture']}
            src={role === 'operator' ? operatorIcon : profImg}
            alt="author avatar"
          />
          <div className={styles['text-and-support-tex']}>
            <div className={styles['text-name']}>
              {role === 'operator' ? 'Anwado Team' : username}
            </div>
            {lawfirm?.length > 0 && (
              <div className={styles['support-text']}> —{lawfirm}</div>
            )}
          </div>
        </div>

        <div className={styles['lawyer-card-social-container']}>
          <button
            data-cy="copy-button"
            className={styles['lawyer-card-button-link']}
            onClick={() => {
              let slug = createUrlSlug('', article_title, username, '');

              slug += `-${article_id}`;

              navigator.clipboard.writeText(
                `${process.env.REACT_APP_DNS_URI}/rechtsinfos/${slug}`
              );
            }}
          >
            <img
              className={styles['copyIcon']}
              src={copyIcon}
              alt="copy icon"
            />
            {LPTranslate('Article_Blog_Page_Details_Gray_Button_Copy_Link')}
          </button>
          <button
            data-cy="gray-button"
            className={styles['lawyer-card-button-profile']}
            onClick={handleClick}
          >
            {role === 'operator'
              ? LPTranslate('Article_Blog_Page_Details_Gray_Button_Homepage')
              : LPTranslate(
                  'Article_Blog_Page_Details_Gray_Button_Lawyer_Profile'
                )}
          </button>
        </div>
      </div>
      {position === 'bottom' && <div className={styles['divider']} />}
    </div>
  );
};
export default ArticleLawyerCard;
