import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { useMutation } from '@apollo/client';

import { UPDATE_SOCIAL_MEDIA_CODE } from 'Services/Queries/operator';
import useAlert from 'Services/Utils/hooks/useAlert';

import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import SimpleTextField from 'Components/Inputs/SimpleTextField';

import 'Assets/styles/operator/OperatorSocialMedia.scss';

const OperatorSocialMedia = () => {
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const { setAlert } = useAlert();

  const [updateSocialMediaCode] = useMutation(UPDATE_SOCIAL_MEDIA_CODE);

  const [facebookCode, setFacebookCode] = useState('');
  const [instagramCode] = useState('');
  const [linkedinCode, setLinkedinCode] = useState('');

  const [facebookCodeError, setFacebookCodeError] = useState('');
  // const [instagramCodeError, setInstagramCodeError] = useState('');
  const [linkedinCodeError, setLinkedinCodeError] = useState('');

  const checkIfFieldsAreEmpty = (socialMediaName) => {
    if (socialMediaName === 'facebook') {
      if (facebookCode === '') {
        setFacebookCodeError('This field cannot be empty.');
        return true;
      }
    } else if (socialMediaName === 'instagram') {
      if (instagramCode === '') {
        // setInstagramCodeError('This field cannot be empty.');
        return true;
      }
    } else if (socialMediaName === 'linkedin') {
      if (linkedinCode === '') {
        setLinkedinCodeError('This field cannot be empty.');
        return true;
      }
    }

    return false;
  };

  const handleErrorResponse = (socialMediaName, response) => {
    if (socialMediaName === 'facebook') setFacebookCodeError(response.message);
    else if (socialMediaName === 'instagram') {
      // setInstagramCodeError(response.message);
    } else if (socialMediaName === 'linkedin')
      setLinkedinCodeError(response.message);
  };

  const handleUpdate = async (socialMediaName) => {
    if (checkIfFieldsAreEmpty(socialMediaName)) return;

    if (socialMediaName === 'facebook') setFacebookCodeError('');
    else if (socialMediaName === 'instagram') {
      // setInstagramCodeError('');
    } else if (socialMediaName === 'linkedin') setLinkedinCodeError('');

    let code = '';

    if (socialMediaName === 'facebook') code = facebookCode;
    else if (socialMediaName === 'instagram') code = instagramCode;
    else if (socialMediaName === 'linkedin') code = linkedinCode;

    const data = {
      platformName: socialMediaName,
      code: code,
    };

    let response = await updateSocialMediaCode({
      variables: { input: data },
    });

    if (!response) {
      setAlert(`Something went wrong`);
      return;
    }

    response = response.data.updateSocialMediaCode;

    if (response.status === 'success') setAlert(`${response.message}`);

    if (response.status === 'error')
      handleErrorResponse(socialMediaName, response);
  };

  return (
    <>
      <h1>Operator Social Media</h1>

      <SettingsMenu
        setUpdateComponent={'/OperatorArticle'}
        role={clientInfoRedux.role}
      />

      <div className="lawp-operator-social-media-content-container">
        <div className="facebook-container">
          <SimpleTextField
            value={facebookCode}
            onChange={(value) => setFacebookCode(value)}
            title="Facebook/Instagram"
            errorMessage={facebookCodeError}
          />
          <Button
            data-cy="submit-button"
            className="updateButton"
            onClick={() => handleUpdate('facebook')}
            form="update-form"
          >
            Update
          </Button>
        </div>
        {/* 
        <div className="facebook-container">
          <SimpleTextField
            value={instagramCode}
            onChange={(value) => setInstagramCode(value)}
            title="Instagram"
            errorMessage={instagramCodeError}
          />
          <Button
            data-cy="submit-button"
            className="updateButton"
            onClick={() => handleUpdate('instagram')}
            form="update-form"
          >
            Update
          </Button>
        </div> */}

        <div className="facebook-container">
          <SimpleTextField
            value={linkedinCode}
            onChange={(value) => setLinkedinCode(value)}
            title="LinkedIn"
            errorMessage={linkedinCodeError}
          />
          <Button
            data-cy="submit-button"
            className="updateButton"
            onClick={() => handleUpdate('linkedin')}
            form="update-form"
          >
            Update
          </Button>
        </div>
      </div>
    </>
  );
};

export default OperatorSocialMedia;
